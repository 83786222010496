import React, { useEffect, useState, useMemo, ChangeEvent, useRef } from 'react';
import { ThemeProvider, CheckboxField, Accordion,Text, View, Flex, Card, Link, Button, SelectField, useTheme, Divider, TextField, Table, TableHead, TableRow, TableCell, TableBody,Image,Message, IconsProvider, Pagination,RadioGroupField, Radio, Alert, useBreakpointValue } from '@aws-amplify/ui-react';
import { Cache } from 'aws-amplify/utils';
import { Helmet } from "react-helmet-async";
import { MdCancelPresentation, MdEdit,MdExpandLess, MdExpandMore,MdSave, MdRestore,MdSearch,MdKeyboardDoubleArrowRight,MdKeyboardDoubleArrowLeft } from "react-icons/md";
import IcoSearchTitle from '../image/ico_search_title.svg'
import IcoResultTitle from '../image/ico_result_title.svg'
import { ImArrowUp, ImArrowDown } from "react-icons/im";
import { PiNewspaperClippingFill, PiBuildingOfficeFill } from "react-icons/pi";
import { FaAngleDown } from "react-icons/fa6";
import { ReactComponent as IcoCsvTable } from '../image/ico_csv_table.svg'
import { ReactComponent as IcoCsvTableHover } from '../image/ico_csv_table_hover.svg'
import { ReactComponent as IcoKigyou } from '../image/ico_kigyou.svg'
import { ReactComponent as IcoSinbun } from '../image/ico_sinbun.svg'
import { ReactComponent as IcoPaginationBack } from '../image/ico_pagination_back.svg'
import { ReactComponent as IcoPaginationNext } from '../image/ico_pagination_next.svg'
import { GrCircleInformation } from "react-icons/gr";
import { useNavigate } from 'react-router-dom';
import { useTokens } from './AuthPage'; // AuthPageからuseTokensをインポート
import { SaveSearchFilter } from './SaveSearchFilter';
import { CallSearchFilter } from './CallSearchFilter';
import CsvDLConfirm, { SearchFilter } from './CsvDLConfirm';
import { SearchFilterCheckboxInput,DataNode } from './SearchFilterCheckboxInput';
import { industryData } from '../data/industryData';
import { regionData } from '../data/regionData';
import Header from '../commons/Header';
import Footer from '../commons/Footer';
import '../css/HomeScreen.css';
import { BsFunnel } from "react-icons/bs";
import { IoMdArrowDropdown } from "react-icons/io";
import { MdNavigateNext } from "react-icons/md";
import { regionClassificationData } from '../data/regionClassificationData';
import IcoFilterOff from '../image/ico_filter_off.svg'
import IcoFilterOn from '../image/ico_filter_on.svg'
import IcoInfo from '../image/ico_info.svg'
import IcoSearchSave from '../image/ico_search_save.svg';
import IcoSearchLoad from '../image/ico_search_load.svg';
import IcoMinus from '../image/ico_minus.svg';
import IcoPlus from '../image/ico_plus.svg';
import HomeScreenPagination from './HomeScreenPagination'


  //企業検索
  interface SearchHomeType {
     outputDate:string,
     industorialClass_code:string,
     address_code:string,
     corporateName:string,
     detailParameter:string
     sortOrder: string,
     ascending_DescendingOrder: string,
     displayCount: string,
     currentPageNumber: string
  }
  interface SearchConditions {
    outputDate:string,
    corporateName:string
    industorialClass_code:string,
    address_code:string,
    detailParameter:string,
    sortOrder:string,
    ascending_DescendingOrder: string,
    displayCount: string,
    currentPageNumber: string,
    businessKeyword1: string,
    businessKeyword2: string,
    businessKeyword3: string,
    businessKeyword4: string,
    businessKeyword5: string,
    salesFrom:string,
    salesTo:string,
    numberOfEmployeesFrom:string,
    numberOfEmployeesTo:string,
    establishmentDateFrom:string,
    establishmentDateTo:string,
    captalStockFrom:string,
    captalStockTo:string,
  }
  interface ResponseDataItem {
    max_search_data_limit:number
    cnt_corporateNumber:number
    cnt_corporateNumber_limitplus1:number
    corporateName: string;
    corporateNumber: string;
    headquarterAddress: string;
    industorialClass_large: string;
    captalStock: number;
    numberOfEmployees: number;
  }
  //桁数チェック
  interface CheckDegitResult {
    checkResult: boolean;
    message: string;
  }
  interface CheckDegitInput {
  itemName: string;
  inputText: string;
  limitNumberOfDigit: number;
  }

  interface CheckDegitRecordType {
    inputInfo: CheckDegitInput[];
  }


  //検索条件保存
  //tb_searchfilter_cnaviに合わせて地域=address
  interface SearchData {
    savedAt: Date | null;
    userName: string;
    industry: string;
    industryCode: string;
    address: string;
    addressCode: string;
    corporateName: string;
    detailCondition: string;
    comments: string;
  }
  
  //関数に渡す型
  interface eventArgsType {
    searchConditionRecordInfo: SearchData;
    saveDate: string | null;
    created_at: string | null;
    updated_at: string | null;
  }
  
  
  //G-Search連携
  interface GsearchServiceType {
    corporateName: string;
    dbid: string;
    T: string;
  }
  

export default function HomeScreen() {
  // ヘッダ読み込み時処理完了フラグ
  const [isLoadedHeader, setIsLoadedHeader] = React.useState(false);
  const { tokens } = useTheme();
  
  // ■■エラーメッセージの定義
  const ERROR_MESSAGE_PROCESSING_ERROR = "処理中にエラーが発生しました。もう一度お試し下さい。";
  
  // ■■トークン関連
  const { getTokens } = useTokens(); // useTokensフックを使用
  const [tokenInfo, setTokenInfo] = useState<any>(null);
  
  // ■■エラー関連
  const [errorMessage1, setErrorMessage1] = useState<string | null>(null); // エラーメッセージ用のstate
  const error1Ref = useRef<HTMLDivElement | null>(null);
  const [errorMessage2, setErrorMessage2] = useState<string | null>(null); // エラーメッセージ用のstate
  const error2Ref = useRef<HTMLDivElement | null>(null);
  const [errorMessage3, setErrorMessage3] = useState<string | null>(null); // エラーメッセージ用のstate
  const error3Ref = useRef<HTMLDivElement | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null); // 成功メッセージ用のstate
  const successRef = useRef<HTMLDivElement | null>(null);
  const [isNeedChangePlan, setIsNeedChangePlan] = useState(false);
  const [isNeedAddPurchase, setIsNeedAddPurchase] = useState(false);
  const [isNeedChangePlan2, setIsNeedChangePlan2] = useState(false);

  // ■■お知らせとデータ更新セクション　関連
  const [homeInfoHtmlContent, setHomeInfoHtmlContent] = useState('');
  const [isPcNoticeAndDataUpdateOpen, setIsPcNoticeAndDataUpdateOpen] = useState(false);
  const [updatedDataList, setUpdatedDataList] = useState<string[]>([]);
  const togglePcNoticeAndDataUpdateSection = () => {
    setIsPcNoticeAndDataUpdateOpen(!isPcNoticeAndDataUpdateOpen);
  };

  const [isSpNoticeOpen, setIsSpNoticeOpen] = useState(false);
  const [isSpDataUpdateOpen, setIsSpDataUpdateOpen] = useState(false);
  
  // ■■ 検索結果表示
  const [tableData, setTableData] = useState<ResponseDataItem[]>([]);
  const [corporateNumList, setCorporateNumList] = useState<string[]>([]);
  const [dataCount, setDataCount] = useState(0);
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState<{ [key: number]: { addressHovered: boolean; industryHovered: boolean; capitalHovered: boolean; membersHovered: boolean } }>({});

  //表示件数
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  //並び替え
  const [sortKey, setSortKey] = useState<keyof ResponseDataItem>("corporateName");
  const [ascDesc, setAscDesc] = useState("ASC");
  const [isAscendingPush, setIsAscendingPush] = useState(true);
  
  //検索条件保存
  const [saveSearchData, setSaveSearchData] = useState<SearchData | null>(null);
  const [saveSearchModalIsOpen, setSaveSearchModalIsOpen] = useState(false);
  const [returnedSaveSearchData, setReturnedSaveSearchData] = useState<SearchData | null>(null);
  
  //検索条件呼び出し
  const [callSearchData, setCallSearchData] = useState<SearchData[]>([]);
  const [callSearchModalIsOpen, setCallSearchModalIsOpen] = useState(false);
  const [returnedCallSearchData, setReturnedCallSearchData] = useState<SearchData | null>(null);
  
  const [currentResultSearchConditions,setCurrentResultSearchConditions] = useState<SearchConditions | null>(null);
  
   // 各業種の企業数レコード保持用
  const [eachIndustrialCompanyNum,setEachIndustrialCompanyNum] = useState<{[key: string]:number}>({});
  
  // 各地域の企業数レコード保持用
  const [eachRegionCompanyNum,setEachRegionCompanyNum] = useState<{[key: string]:number}>({});


  // データ更新表示データ取得済み判定
  const [isFetchedUpdatedData,setIsFetchedUpdatedData] = useState<boolean>(false);
  
  // 各業種の企業数取得済み判定
  const [isFetchedEachIndustrialCompanyNum, setIsFetchedEachIndustrialCompanyNum] = useState<boolean>(false);
  
  // 各地域の企業数取得済み判定
  const [isFetchedEachRegionCompanyNum,setIsFetchedEachRegionCompanyNum] = useState<boolean>(false);
  
  // 詳細条件入力許可判定
  const [allowDetailConditionInput,setAllowDetailConditionInput] = useState<boolean>(false);
  
  // アコーディオン開閉管理
  const [accordionOpenManage,setAccordionOpenManage] = useState<string[] | undefined>([]);

  //画面遷移
  const navigate = useNavigate();
  const isReturning = useRef(false);
  // 入力フィールドの状態
  const [dummy, setDummy] = useState("");
  const [corporateName, setCorporateName] = useState("");
  const [businessContentsFirst, setBusinessContentsFirst] = useState("");
  const [businessContentsSecond, setBusinessContentsSecond] = useState("");
  const [businessContentsThird, setBusinessContentsThird] = useState("");
  const [businessContentsFourth, setBusinessContentsFourth] = useState("");
  const [businessContentsFifth, setBusinessContentsFifth] = useState("");
  const [salesFrom, setSalesFrom] = useState("");
  const [salesTo, setSalesTo] = useState("");
  const [employeesNumFrom,setEmployeesNumFrom] = useState("");
  const [employeesNumTo, setEmployeesNumTo] = useState("");
  const [establishedFrom, setEstablishedFrom] = useState("");
  const [establishedTo, setEstablishedTo] = useState("");
  const [capitalFrom, setCapitalFrom] = useState("");
  const [capitalTo, setCapitalTo] = useState("");
  // 検索結果
  const [searchResults, setSearchResults] = useState([]);
  
  // 処理中フラグ tokenの読み込み済みか
  const [isLoadingToken, setIsLoadingToken] = React.useState(true);
  
  // 処理中フラグ モーダルの多重クリック防止
  const [isProcessing, setIsProcessing] = React.useState(false);
  
  // 処理中フラグ 検索中の多重クリック防止
  const [isSearching, setIsSearching] = React.useState(false);

  // ■■業種・地域・企業名・詳細条件の入力エリア＞業種　関連
  // 大分類、中分類の開閉状態を管理するステート
  const [openCategories, setOpenCategories] = useState<string[]>([]);
  // トグルで開閉を管理
  const handleToggle = (category: string) => {
    setOpenCategories((prev) => {
      if (prev.includes(category)) {
        // オフにする場合
        let newCategories = prev.filter((item) => item !== category);
  
        // 大分類がオフになる場合、中分類もオフにする
        const [mainCategory, subCategory] = category.split(',').slice(0, 2);
        if (subCategory === '00') {
          // 大分類の場合
          newCategories = newCategories.filter((item) => {
            const [itemMainCategory, itemSubCategory] = item.split(',').slice(0, 2);
            // 同じグループで、かつ中分類（サブカテゴリーが '00' ではない）を除外
            return !(itemMainCategory === mainCategory && itemSubCategory !== '00');
          });
        }
  
        return newCategories;
      } else {
        // オンにする場合
        return [...prev, category];
      }
    });
  };

  // CSVダウンロードモーダル表示フラグ
  const [csvDLModalIsOpen, setCsvDLModalIsOpen] = useState(false);
  // 出力企業リスト CSVダウンロードモーダル　
  const [selectedCorporateNumberList, setSelectedCorporateNumberList] = useState<string[]>([]);
  // 検索条件　CSVダウンロードモーダル　　デフォルト値（null許容のPGではないので）
  const deafaultSearchFilter : SearchFilter = {
                  industorialClass:'',
                  address:'',
                  corporateName:'',
                  detailParameter:'',
                  numberOfCorporate:0, 
                  comments:'',
                };
  // 検索条件　CSVダウンロードモーダル　
  const [searchFilterRecord, setSearchFilterRecord] = useState<SearchFilter>(deafaultSearchFilter);
  // CSVダウンロードフラグ
  const [isDownload, setIsDownload] = useState<boolean>(false);

  // 検索結果チェックボックスの状態
  const [masterChecked, setMasterChecked] = useState(true);
  const [otherChecked, setOtherChecked] = useState<boolean[]>(new Array(corporateNumList.length).fill(true));
  
  // セッションによる変更かどうかの判定
  const [isNoSession, setIsNoSession] = useState<boolean>(false);

  useEffect(() => {
    const loadState = async () => {
      try {
        // セッションからの呼び出し
        // 検索条件
        const savedCheckedIndustryItems = JSON.parse(sessionStorage.getItem('checkedIndustryItems') ?? '{}');
        const savedCheckedRegionItems = JSON.parse(sessionStorage.getItem('checkedRegionItems') ?? '{}');
        const savedForNotationRegionItems = JSON.parse(sessionStorage.getItem('forNotationRegionItems') ?? '{}');
        const savedForNotationIndustryItems = JSON.parse(sessionStorage.getItem('forNotationIndustryItems') ?? '{}');
        const savedCorporateName = sessionStorage.getItem('corporateName') ?? "";
        const savedBusinessContentsSearchMethod = sessionStorage.getItem('businessContentsSearchMethod') ?? "AND";
        const savedBusinessContentsFirst = sessionStorage.getItem('businessContentsFirst') ?? "";
        const savedBusinessContentsSecond = sessionStorage.getItem('businessContentsSecond') ?? "";
        const savedBusinessContentsThird =  sessionStorage.getItem('businessContentsThird') ?? "";
        const savedBusinessContentsFourth =  sessionStorage.getItem('businessContentsFourth') ?? "";
        const savedBusinessContentsFifth =  sessionStorage.getItem('businessContentsFifth') ?? "";
        const savedSalesFrom =  sessionStorage.getItem('salesFrom') ?? "";
        const savedSalesTo =  sessionStorage.getItem('salesTo') ?? "";
        const savedEmployeesNumFrom =  sessionStorage.getItem('employeesNumFrom') ?? "";
        const savedEmployeesNumTo =  sessionStorage.getItem('employeesNumTo') ?? "";
        const savedEstablishedFrom =  sessionStorage.getItem('establishedFrom') ?? "";
        const savedEstablishedTo =  sessionStorage.getItem('establishedTo') ?? "";
        const savedCapitalFrom =  sessionStorage.getItem('capitalFrom') ?? "";
        const savedCapitalTo =  sessionStorage.getItem('capitalTo') ?? "";
        const savedCheckedHaveOrNotHaveHomePage = JSON.parse(sessionStorage.getItem('checkedHaveOrNotHaveHomePage') ?? '[true, true]');
        const savedCheckedHaveOrNotHaveContactPage = JSON.parse(sessionStorage.getItem('checkedHaveOrNotHaveContactPage') ?? '[true, true]');
        const savedCheckedListedOrUnListedCompany = JSON.parse(sessionStorage.getItem('checkedListedOrUnListedCompany') ?? '[true, true]');
        const savedCheckedCloseOrOpenCompany = JSON.parse(sessionStorage.getItem('checkedCloseOrOpenCompany') ?? '[false, true]');
        const savedCheckedOutputOrNotOutputCompany = JSON.parse(sessionStorage.getItem('checkedOutputOrNotOutputCompany') ?? '[true, true]');
        
        // セッションに保存済み検索条件があれば取得（上書き）
        const savedCurrentResultSearchConditions = JSON.parse(sessionStorage.getItem('currentResultSearchConditions') ?? 'null');
        
        // ページネーション・並び替え情報
        const savedCurrentPage = JSON.parse(sessionStorage.getItem('currentPage') ?? '1');
        const savedRowsPerPage = JSON.parse(sessionStorage.getItem('rowsPerPage') ?? '20');
        const savedSortKey = sessionStorage.getItem('sortKey') ?? "corporateName";
        const savedAscDesc = sessionStorage.getItem('ascDesc') ?? "ASC";
        const savedIsAscendingPush = JSON.parse(sessionStorage.getItem('isAscendingPush') ?? 'true');
        // 検索結果の表示内容
        const savedDataCount = JSON.parse(sessionStorage.getItem('dataCount') ?? '0');
        const savedTableData = JSON.parse(sessionStorage.getItem('tableData') ?? '[]');
        const savedIsSearchClicked = JSON.parse(sessionStorage.getItem('isSearchClicked') ?? 'false');
        const savedCorporateNumList = JSON.parse(sessionStorage.getItem('corporateNumList') ?? '[]');
        // CSVDL条件　チェックボックス
        const savedSearchFilterRecord = JSON.parse(sessionStorage.getItem('searchFilterRecord') ?? 'null');
        const savedMasterChecked = JSON.parse(sessionStorage.getItem('masterChecked') ?? 'false');
        const savedOtherChecked = JSON.parse(sessionStorage.getItem('otherChecked') ?? '[]');
        
        // セッションの値のセット
        // 検索条件・検索条件保存モーダル
        setCheckedIndustryItems(savedCheckedIndustryItems);
        setForNotationIndustryItems(savedForNotationIndustryItems);
        setCheckedRegionItems(savedCheckedRegionItems);
        setForNotationRegionItems(savedForNotationRegionItems);
        setCorporateName(savedCorporateName);
        if (corporateNameRef.current) corporateNameRef.current.value = savedCorporateName;
        setBusinessContentsSearchMethod(savedBusinessContentsSearchMethod);
        setBusinessContentsFirst(savedBusinessContentsFirst);
        if (businessContentsFirstRef.current) businessContentsFirstRef.current.value = savedBusinessContentsFirst;
        setBusinessContentsSecond(savedBusinessContentsSecond);
        if (businessContentsSecondRef.current) businessContentsSecondRef.current.value = savedBusinessContentsSecond;
        setBusinessContentsThird(savedBusinessContentsThird);
        if (businessContentsThirdRef.current) businessContentsThirdRef.current.value = savedBusinessContentsThird;
        setBusinessContentsFourth(savedBusinessContentsFourth);
        if (businessContentsFourthRef.current) businessContentsFourthRef.current.value = savedBusinessContentsFourth;
        setBusinessContentsFifth(savedBusinessContentsFifth);
        if (businessContentsFifthRef.current) businessContentsFifthRef.current.value = savedBusinessContentsFifth;
        setSalesFrom(savedSalesFrom);
        if (salesFromRef.current) salesFromRef.current.value = savedSalesFrom;
        setSalesTo(savedSalesTo);
        if (salesToRef.current) salesToRef.current.value = savedSalesTo;
        setEmployeesNumFrom(savedEmployeesNumFrom);
        if (employeesNumFromRef.current) employeesNumFromRef.current.value = savedEmployeesNumFrom;
        setEmployeesNumTo(savedEmployeesNumTo);
        if (employeesNumToRef.current) employeesNumToRef.current.value = savedEmployeesNumTo;
        setEstablishedFrom(savedEstablishedFrom);
        if (establishedFromRef.current) establishedFromRef.current.value = savedEstablishedFrom;
        setEstablishedTo(savedEstablishedTo);
        if (establishedToRef.current) establishedToRef.current.value = savedEstablishedTo;
        setCapitalFrom(savedCapitalFrom);
        if (capitalFromRef.current) capitalFromRef.current.value = savedCapitalFrom;
        setCapitalTo(savedCapitalTo);
        if (capitalToRef.current) capitalToRef.current.value = savedCapitalTo;
        setCheckedHaveOrNotHaveHomePage(savedCheckedHaveOrNotHaveHomePage);
        setCheckedHaveOrNotHaveContactPage(savedCheckedHaveOrNotHaveContactPage);
        setCheckedListedOrUnListedCompany(savedCheckedListedOrUnListedCompany);
        setCheckedCloseOrOpenCompany(savedCheckedCloseOrOpenCompany);
        setCheckedOutputOrNotOutputCompany(savedCheckedOutputOrNotOutputCompany);
        // 読み込み時のチェック状態がデフォルト状態と異なる場合、isDetailConditionsCheckboxDifferentFromDefaultStateをtrueにする
        if (
          !(savedCheckedHaveOrNotHaveHomePage[0] && savedCheckedHaveOrNotHaveHomePage[1]) || 
          !(savedCheckedHaveOrNotHaveContactPage[0] && savedCheckedHaveOrNotHaveContactPage[1]) ||
          !(savedCheckedListedOrUnListedCompany[0] && savedCheckedListedOrUnListedCompany[1]) ||
          !(!savedCheckedCloseOrOpenCompany[0] && savedCheckedCloseOrOpenCompany[1]) ||
          !(savedCheckedOutputOrNotOutputCompany[0] && savedCheckedOutputOrNotOutputCompany[1])
        ) {
          isDetailConditionsCheckboxDifferentFromDefaultState.current = true;
        }
        setCurrentResultSearchConditions(savedCurrentResultSearchConditions);
        // ページネーション・並び替え情報
        setCurrentPage(Number(savedCurrentPage));
        setRowsPerPage(Number(savedRowsPerPage));
        setSortKey(savedSortKey as keyof ResponseDataItem);
        setAscDesc(savedAscDesc);
        setIsAscendingPush(savedIsAscendingPush);
        // 検索結果の表示内容
        setDataCount(savedDataCount);
        setTableData(savedTableData);
        setIsSearchClicked(savedIsSearchClicked);
        confirmDetailConditionsIsEnterd(false);
        setCorporateNumList(savedCorporateNumList);
        // CSVDL条件　チェックボックス
        if (savedSearchFilterRecord) setSearchFilterRecord(savedSearchFilterRecord);
        setMasterChecked(savedMasterChecked);
        setOtherChecked(savedOtherChecked);
        
      } catch (error) {
        // エラーハンドリング
        console.error("エラーが発生しました:", error);
      }
    };
  
    loadState();
  }, []);


  // ■■画面読み込み時
  React.useEffect(() => {
    
    
    const handleLoadHeader = async () => {
      // 最新のトークンを取得する
      try {
        // 最新のトークンを取得する
        setIsLoadingToken(true);
        const { decodedAccessTokenInfo, idToken } = await getTokens(); // トークンを取得
        setTokenInfo(decodedAccessTokenInfo); // 取得したdecodedAccessTokenInfoをセット
        setIsLoadingToken(false);
        // 「【ホーム画面】表示内容の取得」の呼び出しと画面値の設定
        fetchUpdatedDataAndSetting();
        fetchEachIndustrialCompanyNumAndSetting();
        fetchEachRegionCompanyNumAndSetting();
      } catch (error) {
        console.error("画面読み込み時エラー:", error);
        setErrorMessage1(ERROR_MESSAGE_PROCESSING_ERROR);
      }
    
    }
    const getHomeInfoContent = async () => {
      const homeInfoHtmlContent = await fetchHomeInfoHtmlContent();
      setHomeInfoHtmlContent(homeInfoHtmlContent);
    }
    if (isLoadedHeader) {
      handleLoadHeader();
      setIsLoadedHeader(false);
      getHomeInfoContent();
    }
    
  },[isLoadedHeader]);
  
  
  // ■■画面読み込み時 「【ホーム画面】表示内容の取得」完了時 
  React.useEffect(() => {
    
    const handleSetAllowDetailConditionInput = () => {
      try {
        if(tokenInfo.plan == "basic"){
          // 詳細条件を入力可に設定
          setAllowDetailConditionInput(true);
        }
      } catch (error) {
        console.error("画面読み込み時エラー:", error);
        setErrorMessage1(ERROR_MESSAGE_PROCESSING_ERROR);
      }
    };
    
    if (isFetchedUpdatedData && isFetchedEachIndustrialCompanyNum && isFetchedEachRegionCompanyNum) {
      handleSetAllowDetailConditionInput();
    }
    
  },[isFetchedUpdatedData, isFetchedEachIndustrialCompanyNum, isFetchedEachRegionCompanyNum]);
  
  // ■■「【ホーム画面】表示内容の取得」の呼び出しと画面値の設定
  // 1. データ更新表示
  const fetchUpdatedDataAndSetting = async () => {
    const responseData = await callShowHomeAndSetting('updateddata'); 
    const updatedDataList:string[] = responseData.vpromotionsite;
    setUpdatedDataList(updatedDataList);
    setIsFetchedUpdatedData(true);
  };
  // 2. 企業数(産業分類単位)
  const fetchEachIndustrialCompanyNumAndSetting = async () => {
    const responseData = await callShowHomeAndSetting('numindustorialclass'); 
    const newEachIndustrialCompanyNum:{[key:string]:number} = JSON.parse(responseData.numindustorialclass.replace(/\n/g,"").replace(/\s+/g,""));
    setEachIndustrialCompanyNum(newEachIndustrialCompanyNum);
    setIsFetchedEachIndustrialCompanyNum(true);
  };
  // 3. 企業数(地域単位)
  const fetchEachRegionCompanyNumAndSetting = async () => {
    const responseData = await callShowHomeAndSetting('numlocalgovernment'); 
    const newEachRegionCompanyNum:{[key:string]:number} = JSON.parse(responseData.numlocalgovernment.replace(/\n/g,"").replace(/\s+/g,""));
    setEachRegionCompanyNum(newEachRegionCompanyNum);
    setIsFetchedEachRegionCompanyNum(true);
  };
  
  // ■■「【ホーム画面】表示内容の取得」の呼び出し
  const callShowHomeAndSetting = async (fetchType:string) => {
    try {
      // idTokenを取得
      const { idToken } = await getTokens();
      
      // API Gatewayを呼び出す
      const baseUrl:string = process.env.REACT_APP_API_BASE_URL || '';
      const path:string = '/event/showhome';
      const apipath = baseUrl.concat(path);
      const response = await fetch(apipath,{
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${idToken}`, // IDトークンをヘッダーに設定
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({'fetchType': fetchType})
      });
      // ステータスコードの確認
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      // レスポンスのJSONデータをパース
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.error('callShowHomeAndSettingエラー:', error);
      throw error;
    }
  };
  
  // ■■「保存上限チェック」の呼び出し
  const callCheckLimitSave = async () => {
    try {
      // idTokenを取得
      const { idToken } = await getTokens();
  
      // API Gatewayを呼び出す
      const baseUrl:string = process.env.REACT_APP_API_BASE_URL || '';
      const path:string = '/common/checklimitsave';
      const apipath = baseUrl.concat(path);
      const response = await fetch(apipath,{
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${idToken}`, // IDトークンをヘッダーに設定
          'Content-Type': 'application/json'
        },
      });
      // ステータスコードの確認
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      // レスポンスのJSONデータをパース
      const responseData = await response.json();
      // レスポンスのbodyから必要なデータを取得
      const { saveFlag, message } = responseData;
      // 保存数の制限が超えた場合の処理
      if (!saveFlag) {
        setErrorMessage2(message); // エラーメッセージを表示
      } else {
        setErrorMessage2(""); // エラーメッセージをクリア
      }
      return saveFlag;
    } catch (error) {
      throw Error;
    }
  };
    
      
    // ■■「桁数チェック」の呼び出し
    const callCheckDegit = async (record:CheckDegitRecordType): Promise<CheckDegitResult[]> => {
      try {
        // idTokenを取得
        const { idToken } = await getTokens();
    
        // API Gatewayを呼び出す
        const baseUrl:string = process.env.REACT_APP_API_BASE_URL || '';
        const path:string = '/common/checkdegit';
        const apipath = baseUrl.concat(path);
        const response = await fetch(apipath,{
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${idToken}`, // IDトークンをヘッダーに設定
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(record)
        });
        // ステータスコードの確認
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        // レスポンスのJSONデータをパース
        const responseData: CheckDegitResult[] = await response.json();
        return responseData;
      } catch (error) {
        setIsSearching(false); // 検索中フラグOFF
        console.error("エラー:", error);
        setErrorMessage3("処理中にエラーが発生しました。もう一度お試し下さい。");
        throw Error;
      }
    };
    
      // おしらせの取得
  const fetchHomeInfoHtmlContent = async () => {
    try {
      const response = await fetch(process.env.PUBLIC_URL + "/homeInfo.html");
      const htmlText = await response.text();
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlText, 'text/html');
      const targetElement = doc.getElementById('contents');
      return targetElement ? targetElement.innerHTML : '';
    } catch (error) {
      console.error('お知らせの取得に失敗しました。', error);
      return '';
    }
  };
    
    // ハンドラー関数 (検索ボタン押下時)
  const handleSearchWithClick = async () => {
    // 検索中は多重検索・クリックを防止
    if (isSearching) {
        return;
    }
    setIsSearching(true); // 検索中フラグOFF
    setCurrentPage(1);
    await handleSearch(true)
    //検索条件をキャッシュに保存
    handleConditionSet();
    saveState();
  };

  //検索処理  
  const handleSearch = async (isFromButtonClick: boolean) => {
  	  const industrialClass_code = Object.keys(forNotationIndustryItems).join(";");
      const address_code = Object.keys(forNotationRegionItems).join(";");
      const getCurrentDateFormatted = (): string => {
        const date = new Date();
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2); // 月は0が1月なので+1
        return `${year}/${month}`;
      };
      let searchConditions:SearchConditions;
      
      if (isFromButtonClick || !currentResultSearchConditions) {
        searchConditions = {
          outputDate:getCurrentDateFormatted(),
          corporateName:corporateNameRef.current?.value ? corporateNameRef.current?.value : "",
          industorialClass_code:industrialClass_code,
          address_code:address_code,
          detailParameter:formatDetailRequirementsForSaveSearchData(),
          sortOrder: sortKey,
          ascending_DescendingOrder: ascDesc,
          displayCount: rowsPerPage.toString(),
          currentPageNumber: "1",
          businessKeyword1:businessContentsFirstRef.current?.value ? businessContentsFirstRef.current?.value: "",
          businessKeyword2:businessContentsSecondRef.current?.value ? businessContentsSecondRef.current?.value: "",
          businessKeyword3:businessContentsThirdRef.current?.value ? businessContentsThirdRef.current?.value: "",
          businessKeyword4:businessContentsFourthRef.current?.value ? businessContentsFourthRef.current?.value: "",
          businessKeyword5:businessContentsFifthRef.current?.value ? businessContentsFifthRef.current?.value: "",
          salesFrom:salesFromRef.current?.value ? salesFromRef.current?.value : "",
          salesTo:salesToRef.current?.value ? salesToRef.current?.value : "",
          numberOfEmployeesFrom:employeesNumFromRef.current?.value ? employeesNumFromRef.current?.value : "",
          numberOfEmployeesTo:employeesNumToRef.current?.value ? employeesNumToRef.current?.value : "",
          establishmentDateFrom:establishedFromRef.current?.value ? establishedFromRef.current?.value : "",
          establishmentDateTo:establishedToRef.current?.value ? establishedToRef.current?.value : "",
          captalStockFrom:capitalFromRef.current?.value ? capitalFromRef.current?.value : "",
          captalStockTo:capitalToRef.current?.value ? capitalToRef.current?.value : "",
        };
      } else {
        searchConditions = {
          outputDate:currentResultSearchConditions.outputDate,
          corporateName:currentResultSearchConditions.corporateName,
          industorialClass_code:currentResultSearchConditions.industorialClass_code,
          address_code:currentResultSearchConditions.address_code,
          detailParameter:currentResultSearchConditions.detailParameter,
          sortOrder: sortKey,
          ascending_DescendingOrder: ascDesc,
          displayCount: rowsPerPage.toString(),
          currentPageNumber: currentPage.toString(),
          businessKeyword1:currentResultSearchConditions.businessKeyword1,
          businessKeyword2:currentResultSearchConditions.businessKeyword2,
          businessKeyword3:currentResultSearchConditions.businessKeyword3,
          businessKeyword4:currentResultSearchConditions.businessKeyword4,
          businessKeyword5:currentResultSearchConditions.businessKeyword5,
          salesFrom:currentResultSearchConditions.salesFrom,
          salesTo:currentResultSearchConditions.salesTo,
          numberOfEmployeesFrom:currentResultSearchConditions.numberOfEmployeesFrom,
          numberOfEmployeesTo:currentResultSearchConditions.numberOfEmployeesTo,
          establishmentDateFrom:currentResultSearchConditions.establishmentDateFrom,
          establishmentDateTo:currentResultSearchConditions.establishmentDateTo,
          captalStockFrom:currentResultSearchConditions.captalStockFrom,
          captalStockTo:currentResultSearchConditions.captalStockTo,
        };
      }
      
      // トークンが読み込めていない時は処理を中断
      if (!tokenInfo) {
        setIsSearching(false); // 検索中フラグOFF
        return;
      }
      
      // 検索処理開始時すべてのエラーメッセージ、成功メッセージをリセットする
      setSuccessMessage("");
      setErrorMessage1("");
      setErrorMessage2("");
      setErrorMessage3("");
      
      //検索するボタン押下時の検索の場合入力チェックを行う(ページ遷移の際の検索では入力チェック済みの同じ条件を使うためチェックは行わない)
      if (isFromButtonClick) {
      //桁数チェックのための整形
        const  inputInfo: CheckDegitRecordType = {
          inputInfo: [
              { itemName: "corporateName", inputText: searchConditions.corporateName, limitNumberOfDigit: 150 },
              { itemName: "businessKeyword1", inputText: searchConditions.businessKeyword1, limitNumberOfDigit: 200 },
              { itemName: "businessKeyword2", inputText: searchConditions.businessKeyword2, limitNumberOfDigit: 200 },
              { itemName: "businessKeyword3", inputText: searchConditions.businessKeyword3, limitNumberOfDigit: 200 },
              { itemName: "businessKeyword4", inputText: searchConditions.businessKeyword4, limitNumberOfDigit: 200 },
              { itemName: "businessKeyword5", inputText: searchConditions.businessKeyword5, limitNumberOfDigit: 200 },
              { itemName: "salesFrom", inputText: searchConditions.salesFrom.toString(), limitNumberOfDigit: 150 },
              { itemName: "salesTo", inputText: searchConditions.salesTo.toString(), limitNumberOfDigit: 150 },
              { itemName: "numberOfEmployeesFrom", inputText: searchConditions.numberOfEmployeesFrom.toString(), limitNumberOfDigit: 8 },
              { itemName: "numberOfEmployeesTo", inputText: searchConditions.numberOfEmployeesTo.toString(), limitNumberOfDigit: 8 },
              { itemName: "establishmentDateFrom", inputText: searchConditions.establishmentDateFrom.toString(), limitNumberOfDigit: 4 },
              { itemName: "establishmentDateTo", inputText: searchConditions.establishmentDateTo.toString(), limitNumberOfDigit: 4 },
              { itemName: "captalStockFrom", inputText: searchConditions.captalStockFrom.toString(), limitNumberOfDigit: 15 },
              { itemName: "captalStockTo", inputText: searchConditions.captalStockTo.toString(), limitNumberOfDigit: 15 }
          ]
        };
        //入力桁数チェック
        const degitResult: CheckDegitResult[] = await callCheckDegit(inputInfo);
        const errorMessages = degitResult
        .filter(result => !result.checkResult)
        .map(result => result.message);
        if (errorMessages.length > 0) {
          setIsSearching(false); // 検索中フラグOFF
          // 一番目のエラーメッセージを表示
          setErrorMessage2(errorMessages[0]); 
          return;
        }
        
        //属性(数値)チェック
        if (!validateSearchConditions(searchConditions)) {
          setIsSearching(false); // 検索中フラグOFF
          // エラーメッセージを設定し、処理を中断
          return;
        }
        
        //範囲チェックFROM,TO
        const sales = validateRange(searchConditions.salesFrom, searchConditions.salesTo);
        if (!sales) {
          setIsSearching(false); // 検索中フラグOFF
          return;
        }
        const employees = validateRange(searchConditions.numberOfEmployeesFrom, searchConditions.numberOfEmployeesTo);
        if (!employees) {
          setIsSearching(false); // 検索中フラグOFF
          return;
        }
        const establishment = validateRange(searchConditions.establishmentDateFrom, searchConditions.establishmentDateTo);
        if (!establishment) {
          setIsSearching(false); // 検索中フラグOFF
          return;
        }
        const captalStock = validateRange(searchConditions.captalStockFrom, searchConditions.captalStockTo);
        if (!captalStock) {
          setIsSearching(false); // 検索中フラグOFF
          return;
        }
        }
        // エラーがある場合はここで処理を中断
        await callSearchHome(searchConditions,isFromButtonClick);
        // CSVダウンロード用検索条件保存
        getSearchFilter()
        
        if (isFromButtonClick || !currentResultSearchConditions) {
          setCurrentResultSearchConditions(searchConditions);
        }
      };
    
    const callSearchHome = async (record:SearchHomeType,isFromButtonClick:boolean) => {
        try {
            const { idToken } = await getTokens();
            const baseUrl = process.env.REACT_APP_API_BASE_URL || '';
            const path = '/event/search';
            const apipath = baseUrl.concat(path);
            const response = await fetch(apipath, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${idToken}`, // IDトークンをヘッダーに設定
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(record)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const responseData = await response.json();
            // レスポンスから表示用データと企業番号一覧を取得
            const searchedData = responseData['bodyVsearch'];
            const corporateNumData = responseData['corporateNumberList'];
            if (searchedData.length === 0) {
              setErrorMessage3("指定された検索条件に一致する結果がありませんでした。");
              // 検索結果に空のリストをセット
              setTableData([]);
              // 検索結果数は0件をセット
              setDataCount(0);
              
              setCorporateNumList([]);
            } else {
            
            const formattedData = searchedData.map((item:ResponseDataItem) => ({
                max_search_data_limit: item.max_search_data_limit,
                cnt_corporateNumber: item.cnt_corporateNumber,
                cnt_corporateNumber_limitplus1: item.cnt_corporateNumber_limitplus1, 
                corporateName: item.corporateName,
                corporateNumber: item.corporateNumber,
                headquarterAddress: item.headquarterAddress,
                industorialClass_large: item.industorialClass_large,
                captalStock: item.captalStock,
                numberOfEmployees: item.numberOfEmployees
            }));
            
            // 検索結果の表示範囲のセット
            setTableData(formattedData);
            setDataCount(formattedData[0].cnt_corporateNumber);
            // 検索結果の企業番号一覧のセット
            if(!isEqualArray(corporateNumData, corporateNumList)){
              setCorporateNumList(corporateNumData);
            }
            // 検索が成功すればエラーメッセージは非表示にする
            setErrorMessage1("");
            setErrorMessage2("");
            setErrorMessage3("");
            // 件数が3000件の場合はメッセージを表示(中断はしない)
            　const maxSearchDataLimit = formattedData[0].max_search_data_limit;
              if(formattedData[0].cnt_corporateNumber_limitplus1 > maxSearchDataLimit){
                setErrorMessage3(`検索結果が${maxSearchDataLimit}件を超えました。先頭の${maxSearchDataLimit}件までを表示します。`);
              }
            }
            if (isFromButtonClick) {
              setIsSearchClicked(true);
            }
        } catch (error) {
            setIsSearching(false); // 検索中フラグOFF
            console.error("エラー:", error);
            setErrorMessage3("処理中にエラーが発生しました。もう一度お試し下さい。");
        }
        // 多重検索・クリックを防止解除
        setIsSearching(false); // 検索中フラグOFF
    };

    //属性(数値)チェック
    function isNumber(value:any) {
      if (typeof value === 'string') {
        value = value.trim();
      }
      return !isNaN(value) && value !== '';
    }
    
    function isEqualArray (array1:string[], array2:string[]) {
       var i = array1.length;
       if (i != array2.length) return false;
    
       while (i--) {
         if (array1[i] !== array2[i]) return false;
       }
       return true;
    };
    
    function validateSearchConditions(conditions:any) {
      if (conditions.salesFrom && !isNumber(conditions.salesFrom)) {
        setErrorMessage2("売上は数字、150桁以内で入力してください。");
        return false;
      }
      if (conditions.salesTo && !isNumber(conditions.salesTo)) {
        setErrorMessage2("売上は数字、150桁以内で入力してください。");
        return false;
      }
      if (conditions.numberOfEmployeesFrom && !isNumber(conditions.numberOfEmployeesFrom)) {
        setErrorMessage2("従業員数は数字、8桁以内で入力してください。");
        return false;
      }
      if (conditions.numberOfEmployeesTo && !isNumber(conditions.numberOfEmployeesTo)) {
        setErrorMessage2("従業員数は数字、8桁以内で入力してください。");
        return false;
      }
      if (conditions.establishmentDateFrom && !isNumber(conditions.establishmentDateFrom)) {
        setErrorMessage2("設立年は数字、4桁以内で入力してください。");
        return false;
      }
      if (conditions.establishmentDateTo && !isNumber(conditions.establishmentDateTo)) {
        setErrorMessage2("設立年は数字、4桁以内で入力してください。");
        return false;
      }
      if (conditions.captalStockFrom && !isNumber(conditions.captalStockFrom)) {
        setErrorMessage2("資本金は数字、15桁以内で入力してください。");
        return false;
      }
      if (conditions.captalStockTo && !isNumber(conditions.captalStockTo)) {
        setErrorMessage2("資本金は数字、15桁以内で入力してください。");
        return false;
      }
      return true;
    }
    //範囲チェック
    function validateRange(from:any, to:any) {
    if (from && to && from > to) {
        setErrorMessage2(`指定した値に矛盾があります。範囲を正しく指定してください。`);
        return false
    }
    return true
  }
    
  // ホバー時処理
  const handleAddressMouseEnter = (index: number) => {
    setHoveredIndex(prevState => ({ ...prevState, [index]: { ...prevState[index], addressHovered: true } }));
  };

  const handleAddressMouseLeave = (index: number) => {
    setHoveredIndex(prevState => ({ ...prevState, [index]: { ...prevState[index], addressHovered: false } }));
  };

  const handleIndustryMouseEnter = (index: number) => {
    setHoveredIndex(prevState => ({ ...prevState, [index]: { ...prevState[index], industryHovered: true } }));
  };

  const handleIndustryMouseLeave = (index: number) => {
    setHoveredIndex(prevState => ({ ...prevState, [index]: { ...prevState[index], industryHovered: false } }));
  };
  
  const handleMembersMouseEnter = (index: number) => {
    setHoveredIndex(prevState => ({ ...prevState, [index]: { ...prevState[index], membersHovered: true } }));
  };

  const handleMembersMouseLeave = (index: number) => {
    setHoveredIndex(prevState => ({ ...prevState, [index]: { ...prevState[index], membersHovered: false } }));
  };
  
  const handleCapitalMouseEnter = (index: number) => {
    setHoveredIndex(prevState => ({ ...prevState, [index]: { ...prevState[index], capitalHovered: true } }));
  };

  const handleCapitalMouseLeave = (index: number) => {
    setHoveredIndex(prevState => ({ ...prevState, [index]: { ...prevState[index], capitalHovered: false } }));
  };
  
    
    //表示件数切替
    const handleRowsPerPageChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
      setIsNoSession(true); // セッション外の操作フラグＯＮ
      setRowsPerPage(Number(event.target.value));
      setCurrentPage(1); // ページ番号初期化
    };

    const indexOfLastItem = currentPage * rowsPerPage;
    const indexOfFirstItem = indexOfLastItem - rowsPerPage;
    const totalPages = Math.ceil(dataCount / rowsPerPage);
    
    // 並び替え項目の切替
    const handleSortChange = async (event: ChangeEvent<HTMLSelectElement>) => {
      setIsNoSession(true); // セッション外の操作フラグＯＮ
      const value = event.target.value as keyof ResponseDataItem;
      if (["corporateName", "headquarterAddress", "industorialClass_large", "captalStock", "numberOfEmployees"].includes(value)) {
        setSortKey(value);
        setCurrentPage(1); // ページ番号初期化
      } else {
        console.error('Invalid sort key:', value);
      }
    };

    const handleOrderChange = async (ascDesc: string, isAscending:boolean) => {
      setIsNoSession(true); // セッション外の操作フラグＯＮ
      setAscDesc(ascDesc)
      setIsAscendingPush(isAscending)
      setCurrentPage(1); // ページ番号初期化
    };
    
    // ページネーション押下時処理
    const handleNextPage = async () => {
        setIsNoSession(true); // セッション外の操作フラグON
        setCurrentPage(totalPages);
    };
    const handlePreviousPage = async () => {
        setIsNoSession(true); // セッション外の操作フラグON
        setCurrentPage(1);
    };
    const handleChangePage = async (newPageIndex?:number, prevPageIndex?:number) => {
        if (newPageIndex) {
            setIsNoSession(true); // セッション外の操作フラグON
            setCurrentPage(newPageIndex);
        }
    };

    // 並び替え、表示件数切替、ページ番号切替時に再読み込み
    useEffect(() => {
      if (isSearchClicked && !isSearching && isNoSession) {
        setIsSearching(true); // 検索中フラグON
        handleSearch(false); // 再読み込み
        setIsNoSession(false); // セッション外の操作フラグOFF
      }
    }, [currentPage, ascDesc, sortKey, rowsPerPage]);
    

    //検索条件保存
    useEffect(() => {
      if (!saveSearchModalIsOpen && saveSearchData) {
        setReturnedSaveSearchData(saveSearchData);
      }
    }, [saveSearchModalIsOpen]);
  
    async function openSaveSearchModal() {
      const saveSearchData:SearchData = {
        savedAt: new Date(),
        userName: "",
        industry:  Object.values(forNotationIndustryItems).join(";"),
        industryCode: Object.keys(forNotationIndustryItems).join(";"),
        address: Object.values(forNotationRegionItems).join(";"),
        addressCode:  Object.keys(forNotationRegionItems).join(";"),
        corporateName: corporateNameRef.current?.value ? corporateNameRef.current?.value : "",
        detailCondition: formatDetailRequirementsForSaveSearchData(),
        comments: ""
      };
      
      if (isProcessing) {
            return;
        }
        setIsProcessing(true);
        
        // 検索条件保存モーダル表示処理開始時すべてのエラーメッセージ、成功メッセージをリセットする
        setSuccessMessage("");
        setErrorMessage1("");
        setErrorMessage2("");
        setErrorMessage3("");
        
        if(tokenInfo.plan == "basic"){
        setSaveSearchData(saveSearchData);

        try {
            const saveFlag = await callCheckLimitSave();
            if (saveFlag === true) {
              setSaveSearchModalIsOpen(true);
            }else{
              // saveFlagがfalseの場合処理を中断
              return;
            }
          } catch (error) {
            console.error("保存上限チェックでエラーが発生しました:", error);
            setErrorMessage2(ERROR_MESSAGE_PROCESSING_ERROR);
          } finally {
            setIsProcessing(false);
          }
        } else {
          setErrorMessage2("現在のプランでは検索条件を保存できません。ベーシックプランへのアップグレードが必要です。")
          setIsNeedChangePlan(true);
          setIsProcessing(false);
        }
    }
    
    // コールバック関数
    const handleSaveSearchData = async (saveData:eventArgsType) => {
        if (isProcessing) {
            return;
        }
        setIsProcessing(true);
    
        try {
            const { idToken } = await getTokens(); // 最新のトークンを取得
            
            //保存上限値チェック
            const CheckLimitSaveResult = await callCheckLimitSave();
            if (!CheckLimitSaveResult) {
                setErrorMessage1(ERROR_MESSAGE_PROCESSING_ERROR);
                setSuccessMessage("")
                return;
            }
    
            // 検索条件保存
            const SaveSearchFilterResult = await callSaveSearchFilter(saveData);
            if(SaveSearchFilterResult.errFlag == "TRUE"){
                setErrorMessage2(SaveSearchFilterResult.err_message);
                setSuccessMessage("")
            } else {
                setSuccessMessage("検索条件の保存が完了しました");
                setErrorMessage1("");
                setErrorMessage2("");
                setErrorMessage3("");
                setIsNeedChangePlan(false);
                setIsNeedAddPurchase(false);
                setIsNeedChangePlan2(false);
            }
        } catch (error) {
            console.error("Error saving search filter:", error);
            setErrorMessage1(ERROR_MESSAGE_PROCESSING_ERROR);
            setSuccessMessage("")
        } finally {
            setIsProcessing(false);
            setSaveSearchModalIsOpen(false); // モーダルを閉じる
        }
    }
    
    // 検索条件保存APIの呼び出し
    const callSaveSearchFilter = async (record:eventArgsType) => {
      try {
        // idTokenを取得
        const { idToken } = await getTokens();
    
        // API Gatewayを呼び出す
        const baseUrl:string = process.env.REACT_APP_API_BASE_URL || '';
        const path:string = '/event/savesearchfilter';
        const apipath = baseUrl.concat(path);
        console.log("responseの中身:", apipath);
        const response = await fetch(apipath,{
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${idToken}`, // IDトークンをヘッダーに設定
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(record)
          
        });
        console.log("responseの中身:", response);
        // ステータスコードの確認
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        // レスポンスのJSONデータをパース
        const responseData = await response.json();
        console.log("savesearchfilterAPIのresponseDataの中身:", responseData);
        // レスポンスのbodyから必要なデータを取得
        const { err_message, body, errFlag } = responseData;
        // 保存数の制限が超えた場合の処理
        if (errFlag) {
          setErrorMessage1(err_message); // エラーメッセージを表示
          setSuccessMessage("")
        } else {
          console.log("callsavesearchfilter成功");
          setErrorMessage1(""); // エラーメッセージをクリア
        }
        return errFlag;
      } catch (error) {
        console.error("callSaveSearchFilter:", error);
        throw Error;
      }
    };
  
    function closeSaveSearchModal(data: null | string) {
      setSaveSearchModalIsOpen(false);
    }
 
 
  
  // 業種：チェックされたチェックボックスの状態管理用辞書型データ
  const [checkedIndustryItems,setCheckedIndustryItems] = useState<{[key: string]: boolean}>({});
  // 業種：チェックされたdataDictのコードを「>」で連結して状態管理する用の辞書型データ
  const [forNotationIndustryItems,setForNotationIndustryItems] = useState<{[key: string]: string}>({});
  
  // 地域：チェックされたチェックボックスの状態管理用辞書型データ
  const [checkedRegionItems,setCheckedRegionItems] = useState<{[key: string]: boolean}>({});
  // 地域：チェックされたdataDictのコードを「>」で連結して状態管理する用の辞書型データ
  const [forNotationRegionItems,setForNotationRegionItems] = useState<{[key: string]: string}>({});
  
    // 地域分類(地方分類用):チェックされたチェックボックスの状態管理用辞書型データ
  const [openRegionClassificationItems,setOpenRegionClassificationItems] = useState<{[key: string]: boolean}>({});
  
  // 地域分類(地方分類用):データを使用しやすいように地域分類用データの型を合わせる
  const regionClassificationDataDict:{[key: string]: string[]} = regionClassificationData;


  // 地域分類の地方チェックボックス押下時に下位階層の都道府県分類チェックボックスを表示・非表示するための処理
  const handleClassificationCheck:(itemName:string,dataDict:{[key: string]: DataNode}) => void = (itemName,dataDict) => {
        // 処理終了前にcheckedItemsにセットするための更新後データを入れる辞書型データ
        const newOpenRegionClassificationItems:{[key: string]: boolean} = {...openRegionClassificationItems};
        
        // チェックボックスのチェックをつけた場合はtrue,外した場合はfalse
        const isChecked = !openRegionClassificationItems[itemName];
        if (isChecked) {
          newOpenRegionClassificationItems[itemName] = isChecked;
        } else {
          delete newOpenRegionClassificationItems[itemName];
        }
        
        // 更新後データをcheckedItemsにセットする
        setOpenRegionClassificationItems(newOpenRegionClassificationItems);
    }


  const corporateNameRef = useRef<HTMLInputElement>(null);
  
  // 詳細条件エリアの入力管理
  const [businessContentsSearchMethod,setBusinessContentsSearchMethod] = useState<string>("AND");
  const businessContentsFirstRef = useRef<HTMLInputElement>(null);
  const businessContentsSecondRef = useRef<HTMLInputElement>(null);
  const businessContentsThirdRef = useRef<HTMLInputElement>(null);
  const businessContentsFourthRef = useRef<HTMLInputElement>(null);
  const businessContentsFifthRef = useRef<HTMLInputElement>(null);
  
  const salesFromRef = useRef<HTMLInputElement>(null);
  const salesToRef = useRef<HTMLInputElement>(null);
  
  const employeesNumFromRef = useRef<HTMLInputElement>(null);
  const employeesNumToRef = useRef<HTMLInputElement>(null);
  
  const establishedFromRef = useRef<HTMLInputElement>(null);
  const establishedToRef = useRef<HTMLInputElement>(null);
  
  const capitalFromRef = useRef<HTMLInputElement>(null);
  const capitalToRef = useRef<HTMLInputElement>(null);
  
  const [checkedHaveOrNotHaveHomePage,setCheckedHaveOrNotHaveHomePage] = useState<boolean[]>([true,true]);
  const [checkedHaveOrNotHaveContactPage,setCheckedHaveOrNotHaveContactPage] = useState<boolean[]>([true,true]);
  const [checkedListedOrUnListedCompany,setCheckedListedOrUnListedCompany] = useState<boolean[]>([true,true]);
  const [checkedCloseOrOpenCompany,setCheckedCloseOrOpenCompany] = useState<boolean[]>([false,true]);
  const [checkedOutputOrNotOutputCompany,setCheckedOutputOrNotOutputCompany] = useState<boolean[]>([true,true]);
    
  const [isEnteredDetailConditions,setIsEnteredDetailConditions] = useState<boolean>(false);
  
  const isDetailConditionsCheckboxDifferentFromDefaultState = useRef<boolean>(false);
  
  function handleDetailRequirementsChecked(checkedItemState:boolean[],setCheckedItemState:(checkedItemState:boolean[]) => void,checkedItemIndex:number) {
    const newCheckedItemState:boolean[] = [...checkedItemState];
    newCheckedItemState[checkedItemIndex] = !checkedItemState[checkedItemIndex];
    setCheckedItemState(newCheckedItemState);
  }
  
  function setCorporateNameRefValue(corporateName:string) {
    if (corporateNameRef.current) {
      corporateNameRef.current.value = corporateName;
    }
  }
  
  function formatDetailRequirementsForSaveSearchData() {
    let resultString:string = "";
    
    resultString += "【事業内容】" ;
    if ((businessContentsFirstRef.current?.value ? businessContentsFirstRef.current?.value : "").length > 0 || 
        (businessContentsSecondRef.current?.value ? businessContentsSecondRef.current?.value : "").length > 0 || 
        (businessContentsThirdRef.current?.value ? businessContentsThirdRef.current?.value : "").length > 0 || 
        (businessContentsFourthRef.current?.value ? businessContentsFourthRef.current?.value : "").length > 0 || 
        (businessContentsFifthRef.current?.value ? businessContentsFifthRef.current?.value : "").length > 0
    ) {
      resultString += businessContentsSearchMethod + ","
    }
    resultString += [businessContentsFirstRef.current?.value,businessContentsSecondRef.current?.value,businessContentsThirdRef.current?.value,businessContentsFourthRef.current?.value,businessContentsFifthRef.current?.value]
      .filter((businessContentsRefValue) => businessContentsRefValue !== undefined && businessContentsRefValue.length > 0)
      .join(",");
    resultString += ";"
    
    resultString += "【売上】"+salesFromRef.current?.value + ((salesFromRef.current?.value ? salesFromRef.current?.value : "").length > 0 ? "万円" : "") + (((salesFromRef.current?.value ? salesFromRef.current?.value : "").length > 0 || (salesToRef.current?.value ? salesToRef.current?.value : "").length > 0) ? "～" : "") + salesToRef.current?.value + ((salesToRef.current?.value ? salesToRef.current?.value : "").length > 0 ? "万円" : "") + ";";
    resultString += "【従業員数】"+employeesNumFromRef.current?.value + ((employeesNumFromRef.current?.value ? employeesNumFromRef.current?.value : "").length > 0 ? "人" : "") + (((employeesNumFromRef.current?.value ? employeesNumFromRef.current?.value : "").length > 0 || (employeesNumToRef.current?.value ? employeesNumToRef.current?.value : "").length > 0) ? "～" : "") + employeesNumToRef.current?.value + ((employeesNumToRef.current?.value ? employeesNumToRef.current?.value : "").length > 0 ? "人" : "") + ";";
    resultString += "【設立年】"+establishedFromRef.current?.value + ((establishedFromRef.current?.value ? establishedFromRef.current?.value : "").length > 0 ? "年" : "") + (((establishedFromRef.current?.value ? establishedFromRef.current?.value : "").length > 0 || (establishedToRef.current?.value ? establishedToRef.current?.value : "").length > 0) ? "～" : "") + establishedToRef.current?.value + ((establishedToRef.current?.value ? establishedToRef.current?.value : "").length > 0 ? "年" : "") + ";";
    resultString += "【資本金】"+capitalFromRef.current?.value + ((capitalFromRef.current?.value ? capitalFromRef.current?.value : "").length > 0 ? "万円" : "" ) + (((capitalFromRef.current?.value ? capitalFromRef.current?.value : "").length > 0 || (capitalToRef.current?.value ? capitalToRef.current?.value : "").length > 0) ? "～" : "") + capitalToRef.current?.value + ((capitalToRef.current?.value ? capitalToRef.current?.value : "").length > 0 ? "万円" : "" ) + ";";
    resultString += "【URL】" + (checkedHaveOrNotHaveHomePage[0] ? "有" : "") + (checkedHaveOrNotHaveHomePage[0] && checkedHaveOrNotHaveHomePage[1] ? "," : "") + (checkedHaveOrNotHaveHomePage[1] ? "無" : "") + ";";
    resultString += "【問合せURL】" + (checkedHaveOrNotHaveContactPage[0] ? "有" : "") + (checkedHaveOrNotHaveContactPage[0] && checkedHaveOrNotHaveContactPage[1] ? "," : "") + (checkedHaveOrNotHaveContactPage[1] ? "無" : "") + ";";
    resultString += "【上場・非上場】" + (checkedListedOrUnListedCompany[0] ? "上場" : "") + (checkedListedOrUnListedCompany[0] && checkedListedOrUnListedCompany[1] ? "," : "") + (checkedListedOrUnListedCompany[1] ? "非上場" : "") + ";";
    resultString += "【閉鎖企業】" + (checkedCloseOrOpenCompany[0] ? "有" : "") + (checkedCloseOrOpenCompany[0] && checkedCloseOrOpenCompany[1] ? "," : "") + (checkedCloseOrOpenCompany[1] ? "無" : "") + ";";
    resultString += "【出力済み企業】" + (checkedOutputOrNotOutputCompany[0] ? "有" : "") + (checkedOutputOrNotOutputCompany[0] && checkedOutputOrNotOutputCompany[1] ? "," : "") + (checkedOutputOrNotOutputCompany[1] ? "無" : "") + ";";
    
    return resultString;
    
  } 
  function distributeDetailRequirementsForSaveSearchData(detailCondition:string) {
    const splitDetailCondition:string[] = detailCondition.split(";");
    splitDetailCondition.forEach((detailConditionItemString:string,index:number) => {
      if (detailConditionItemString.slice(1,detailConditionItemString.indexOf("】")) === "事業内容") {
        const excludeBusinessContentTitleString = detailConditionItemString.slice(detailConditionItemString.indexOf("】")+1);
        if (excludeBusinessContentTitleString.length > 0) {
          excludeBusinessContentTitleString.split(",").forEach((businessContentItem,index) => {
            if (index === 0) {
              setBusinessContentsSearchMethod(businessContentItem); 
            } else if (index === 1) {
              if (businessContentsFirstRef.current) {
                businessContentsFirstRef.current.value = businessContentItem;
              }
            } else if (index === 2) {
              if (businessContentsSecondRef.current) {
                businessContentsSecondRef.current.value = businessContentItem;
              }
            } else if (index === 3) {
              if (businessContentsThirdRef.current) {
                businessContentsThirdRef.current.value = businessContentItem;
              }
            } else if (index === 4) {
              if (businessContentsFourthRef.current) {
                businessContentsFourthRef.current.value = businessContentItem;
              }
            } else if (index === 5) {
              if (businessContentsFifthRef.current) {
                businessContentsFifthRef.current.value = businessContentItem;
              }
            } 
          })
        }
      }
      else if (detailConditionItemString.slice(1,detailConditionItemString.indexOf("】")) === "売上") {
        const excludeSalesTitleString = detailConditionItemString.slice(detailConditionItemString.indexOf("】")+1);
        if (excludeSalesTitleString.length > 0) {
          const salesItems: string[] = excludeSalesTitleString.split("～");
          if (salesFromRef.current) {
            let numMatch = salesItems[0].match(/\d+/);
            salesFromRef.current.value = (salesItems[0] !== "" && numMatch !== null) ? numMatch[0] : "";
          } 
          if (salesToRef.current) {
            let numMatch = salesItems[1].match(/\d+/);
            salesToRef.current.value = (salesItems[1] !== "" && numMatch !== null) ? numMatch[0] : "";
          }
        }
      }
      else if (detailConditionItemString.slice(1,detailConditionItemString.indexOf("】")) === "従業員数") {
        const excludeEmployeesNumTitleString = detailConditionItemString.slice(detailConditionItemString.indexOf("】")+1);
        if (excludeEmployeesNumTitleString.length > 0) {
          const employeesNumItems: string[] = excludeEmployeesNumTitleString.split("～");
          if (employeesNumFromRef.current) {
            let numMatch = employeesNumItems[0].match(/\d+/);
            employeesNumFromRef.current.value = (employeesNumItems[0] !== "" && numMatch !== null) ? numMatch[0] : "";
          } 
          if (employeesNumToRef.current) {
            let numMatch = employeesNumItems[1].match(/\d+/);
            employeesNumToRef.current.value = (employeesNumItems[1] !== "" && numMatch !== null) ? numMatch[0] : "";
          }
        }
      }
      else if (detailConditionItemString.slice(1,detailConditionItemString.indexOf("】")) === "設立年") {
        const excludeEstablishedTitleString = detailConditionItemString.slice(detailConditionItemString.indexOf("】")+1);
        if (excludeEstablishedTitleString.length > 0) {
          const establishedItems: string[] = excludeEstablishedTitleString.split("～");
          if (establishedFromRef.current) {
            let numMatch = establishedItems[0].match(/\d+/);
            establishedFromRef.current.value = (establishedItems[0] !== "" && numMatch !== null) ? numMatch[0] : "";
          } 
          if (establishedToRef.current) {
            let numMatch = establishedItems[1].match(/\d+/);
            establishedToRef.current.value = (establishedItems[1] !== "" && numMatch !== null) ? numMatch[0] : "";
          }
        }
      }
      else if (detailConditionItemString.slice(1,detailConditionItemString.indexOf("】")) === "資本金") {
        const excludeCapitalTitleString = detailConditionItemString.slice(detailConditionItemString.indexOf("】")+1);
        if (excludeCapitalTitleString.length > 0) {
          const capitalItems: string[] = excludeCapitalTitleString.split("～");
          if (capitalFromRef.current) {
            let numMatch = capitalItems[0].match(/\d+/);
            capitalFromRef.current.value = (capitalItems[0] !== "" && numMatch !== null) ? numMatch[0] : "";
          } 
          if (capitalToRef.current) {
            let numMatch = capitalItems[1].match(/\d+/);
            capitalToRef.current.value = (capitalItems[1] !== "" && numMatch !== null) ? numMatch[0] : "";
          }
        }
      }
      else if (detailConditionItemString.slice(1,detailConditionItemString.indexOf("】")) === "URL") {
        const excludeHaveHomePageTitleString = detailConditionItemString.slice(detailConditionItemString.indexOf("】")+1);
        if (excludeHaveHomePageTitleString.length > 0) {
          const haveHomePageItems: string[] = excludeHaveHomePageTitleString.split(",");
          const newCheckedHaveOrNotHaveHomePage:boolean[] = [false,false];
          if (haveHomePageItems[0] === "有") {
            newCheckedHaveOrNotHaveHomePage[0] = true;
            if (haveHomePageItems.length > 1) {
              newCheckedHaveOrNotHaveHomePage[1] = true;
            }
          } else if (haveHomePageItems[0] === "無") {
            newCheckedHaveOrNotHaveHomePage[1] = true;
          }
          setCheckedHaveOrNotHaveHomePage(newCheckedHaveOrNotHaveHomePage);
          
          // 更新後のチェック状態がデフォルト状態と異なる場合、isDetailConditionsCheckboxDifferentFromDefaultStateをtrueにする
          if (!(newCheckedHaveOrNotHaveHomePage[0] && newCheckedHaveOrNotHaveHomePage[1])) {
            isDetailConditionsCheckboxDifferentFromDefaultState.current = true
          }
          
        }
      }
      else if (detailConditionItemString.slice(1,detailConditionItemString.indexOf("】")) === "問合せURL") {
        const excludeHaveContactPageTitleString = detailConditionItemString.slice(detailConditionItemString.indexOf("】")+1);
        if (excludeHaveContactPageTitleString.length > 0) {
          const haveContactPageItems: string[] = excludeHaveContactPageTitleString.split(",");
          const newCheckedHaveOrNotHaveContactPage = [false,false];
          if (haveContactPageItems[0] === "有") {
            newCheckedHaveOrNotHaveContactPage[0] = true;
            if (haveContactPageItems.length > 1) {
              newCheckedHaveOrNotHaveContactPage[1] = true;
            }
          } else if (haveContactPageItems[0] === "無") {
            newCheckedHaveOrNotHaveContactPage[1] = true;
          }
          setCheckedHaveOrNotHaveContactPage(newCheckedHaveOrNotHaveContactPage);
          
          // 更新後のチェック状態がデフォルト状態と異なる場合、isDetailConditionsCheckboxDifferentFromDefaultStateをtrueにする
          if (!(newCheckedHaveOrNotHaveContactPage[0] && newCheckedHaveOrNotHaveContactPage[1])) {
            isDetailConditionsCheckboxDifferentFromDefaultState.current = true
          }
          
        }
      }
      else if (detailConditionItemString.slice(1,detailConditionItemString.indexOf("】")) === "上場・非上場") {
        const excludeListedCompanyTitleString = detailConditionItemString.slice(detailConditionItemString.indexOf("】")+1);
        if (excludeListedCompanyTitleString.length > 0) {
          const haveListedCompanyItems: string[] = excludeListedCompanyTitleString.split(",");
          const newCheckedListedOrUnListedCompany = [false,false];
          if (haveListedCompanyItems[0] === "上場") {
            newCheckedListedOrUnListedCompany[0] = true;
            if (haveListedCompanyItems.length > 1) {
              newCheckedListedOrUnListedCompany[1] = true;
            }
          } else if (haveListedCompanyItems[0] === "非上場") {
            newCheckedListedOrUnListedCompany[1] = true;
          }
          setCheckedListedOrUnListedCompany(newCheckedListedOrUnListedCompany);
          
          // 更新後のチェック状態がデフォルト状態と異なる場合、isDetailConditionsCheckboxDifferentFromDefaultStateをtrueにする
          if (!(newCheckedListedOrUnListedCompany[0] && newCheckedListedOrUnListedCompany[1])) {
            isDetailConditionsCheckboxDifferentFromDefaultState.current = true
          }
          
        }
      }
      else if (detailConditionItemString.slice(1,detailConditionItemString.indexOf("】")) === "閉鎖企業") {
        const excludeCloseCompanyTitleString = detailConditionItemString.slice(detailConditionItemString.indexOf("】")+1);
        if (excludeCloseCompanyTitleString.length > 0) {
          const closeCompanyItems: string[] = excludeCloseCompanyTitleString.split(",");
          const newCheckedCloseOrOpenCompany = [false,false];
          if (excludeCloseCompanyTitleString[0] === "有") {
            newCheckedCloseOrOpenCompany[0] = true;
            if (excludeCloseCompanyTitleString.length > 1) {
              newCheckedCloseOrOpenCompany[1] = true;
            }
          } else if (excludeCloseCompanyTitleString[0] === "無") {
            newCheckedCloseOrOpenCompany[1] = true;
          }
          setCheckedCloseOrOpenCompany(newCheckedCloseOrOpenCompany);
          
          // 更新後のチェック状態がデフォルト状態と異なる場合、isDetailConditionsCheckboxDifferentFromDefaultStateをtrueにする
          if (!(!newCheckedCloseOrOpenCompany[0] && newCheckedCloseOrOpenCompany[1])) {
            isDetailConditionsCheckboxDifferentFromDefaultState.current = true
          }
          
        }
      }
      else if (detailConditionItemString.slice(1,detailConditionItemString.indexOf("】")) === "出力済み企業") {
        const excludeOutputCompanyTitleString = detailConditionItemString.slice(detailConditionItemString.indexOf("】")+1);
        if (excludeOutputCompanyTitleString.length > 0) {
          const outputCompanyItems: string[] = excludeOutputCompanyTitleString.split(",");
          const newCheckedOutputOrNotOutputCompany = [false,false];
          if (outputCompanyItems[0] === "有") {
            newCheckedOutputOrNotOutputCompany[0] = true;
            if (excludeOutputCompanyTitleString.length > 1) {
              newCheckedOutputOrNotOutputCompany[1] = true;
            }
          } else if (outputCompanyItems[0] === "無") {
            newCheckedOutputOrNotOutputCompany[1] = true;
          }
          setCheckedOutputOrNotOutputCompany(newCheckedOutputOrNotOutputCompany);
          
          // 更新後のチェック状態がデフォルト状態と異なる場合、isDetailConditionsCheckboxDifferentFromDefaultStateをtrueにする
          if (!(newCheckedOutputOrNotOutputCompany[0] && newCheckedOutputOrNotOutputCompany[1])) {
            isDetailConditionsCheckboxDifferentFromDefaultState.current = true
          }
          
        }
      }
    })
  } 
  
  // 詳細条件が入力されているか確認し、入力されていればisEnteredDetailConditionsにtrueを入れる
  function confirmDetailConditionsIsEnterd(isCheckEvent:boolean) {
    if (isCheckEvent) {
      if (isDetailConditionsCheckboxDifferentFromDefaultState.current) {
        if (!isEnteredDetailConditions) {
          setIsEnteredDetailConditions(true);
        }
        return;
      } else {
        isDetailConditionsCheckboxDifferentFromDefaultState.current = true;
        setIsEnteredDetailConditions(true);
        return;
      }
    } else {
      if (isDetailConditionsCheckboxDifferentFromDefaultState.current) {
        if (!isEnteredDetailConditions) {
          setIsEnteredDetailConditions(true);
        }
        return;
      }
    }
    
    if (confirmDetailCoditionsTextBoxIsEnterd()) {
      if (!isEnteredDetailConditions) {
        setIsEnteredDetailConditions(true);
      }
      return;
    } 
    
    setIsEnteredDetailConditions(false);
  }
  
  function confirmDetailCoditionsTextBoxIsEnterd():boolean {
    if ((businessContentsFirstRef.current && businessContentsFirstRef.current.value) || 
      (businessContentsSecondRef.current && businessContentsSecondRef.current.value)　|| 
      (businessContentsThirdRef.current && businessContentsThirdRef.current.value) || 
      (businessContentsFourthRef.current && businessContentsFourthRef.current.value) || 
      (businessContentsFifthRef.current && businessContentsFifthRef.current.value)
    ) {
      return true;
    }
  
    if ((salesFromRef.current && salesFromRef.current.value) || 
        (salesToRef.current && salesToRef.current.value)
    ) {
      return true;
    }
    
    if ((employeesNumFromRef.current && employeesNumFromRef.current.value) || 
        (employeesNumToRef.current && employeesNumToRef.current.value)
    ) {
      return true;
    }
    if ((establishedFromRef.current && establishedFromRef.current.value) || 
        (establishedToRef.current && establishedToRef.current.value)
    ) {
      return true;
    }
    if ((capitalFromRef.current && capitalFromRef.current.value) || 
      (capitalToRef.current && capitalToRef.current.value)
    ) {
      return true;
    }
    
    return false;
  }
  // 地域の都道府県のチェックボックス一覧表示順を指定するための配列
  const prefectureSortingList:string[] = 
    ["010006","020001","030007","040002","050008","060003","070009","080004","090000","100005","110001","120006","130001","140007","150002","160008","170003","180009","190004","200000","210005","220001",
    "230006","240001","250007","260002","270008","280003","290009","300004","310000","320005","330001","340006","350001","360007","370002","380008","390003","400009","410004","420000","430005","440001","450006","460001","470007"];

  async function callSearchRequirement() {
    const sampleSaveSearchData: SearchData = {
      savedAt: null,
      userName: "",
      industry: "農業，林業>農業;農業，林業>林業>管理，補助的経済活動を行う事業所（02林業）;鉱業，採石業，砂利採取業>鉱業，採石業，砂利採取業>管理，補助的経済活動を行う事業所（05鉱業，採石業，砂利採取業）>その他の管理，補助的経済活動を行う事業所",
      industryCode: "A,00,000,0000>A,01,000,0000;A,00,000,0000>A,02,000,0000>A,02,020,0000;C,00,000,0000>C,05,000,0000>C,05,050,0000>C,05,050,0509",
      address: "北海道>札幌市>北区;北海道>札幌市>東区;北海道>函館市;岩手県>大船渡市",
      addressCode: "010006>011002>011029;010006>011002>011037;010006>012025;030007>032034",
      corporateName: "",
      detailCondition: "",
      comments: ""
    }
    
    mappingNamesToCodes(sampleSaveSearchData);
    inputDataBasedOnCallSearchRequirment(sampleSaveSearchData);
  }
  
  React.useEffect(() => {
    if (returnedCallSearchData) {
      clearSearchRequirements();
      mappingNamesToCodes(returnedCallSearchData);
      inputDataBasedOnCallSearchRequirment(returnedCallSearchData);
      setCorporateNameRefValue(returnedCallSearchData.corporateName);
      distributeDetailRequirementsForSaveSearchData(returnedCallSearchData.detailCondition);
      confirmDetailConditionsIsEnterd(false);
    }
  }, [returnedCallSearchData]);
  
  function inputDataBasedOnCallSearchRequirment(selectedSearchRequirement: SearchData) {
    const newCheckedIndustryItems: {[key: string]: boolean} = {};
    const newCheckedRegionItems: {[key: string]: boolean} = {};
    
    if (selectedSearchRequirement.industryCode) {
      selectedSearchRequirement.industryCode.split(";").forEach((industryCodeConcatString) => {
        industryCodeConcatString.split(">").forEach((industryCode:string) => {
          newCheckedIndustryItems[industryCode] = true;
        })
      })
    }
 
    if (selectedSearchRequirement.addressCode) {
      selectedSearchRequirement.addressCode.split(";").forEach((regionCodeConcatString) => {
        regionCodeConcatString.split(">").forEach((regionCode) => {
          newCheckedRegionItems[regionCode] = true;
        })
      })
    }
    
    setCheckedIndustryItems(newCheckedIndustryItems);
    setCheckedRegionItems(newCheckedRegionItems);
  }
  
  function clearSearchRequirements() {
    // 検索条件リセット時すべてのエラーメッセージ、成功メッセージをリセットする
    setSuccessMessage("");
    setErrorMessage1("");
    setErrorMessage2("");
    setErrorMessage3("");
    
    setCheckedIndustryItems({});
    setCheckedRegionItems({});
    setForNotationIndustryItems({});
    setForNotationRegionItems({});
    setOpenRegionClassificationItems({});
    if (corporateNameRef.current) {
      corporateNameRef.current.value = "";
    }
    
    if (businessContentsFirstRef.current) {
      businessContentsFirstRef.current.value = "";
    }
    if (businessContentsSecondRef.current) {
      businessContentsSecondRef.current.value = "";
    }
    if (businessContentsThirdRef.current) {
      businessContentsThirdRef.current.value = "";
    }
    if (businessContentsFourthRef.current) {
      businessContentsFourthRef.current.value = "";
    }
    if (businessContentsFifthRef.current) {
      businessContentsFifthRef.current.value = "";
    }

    if (salesFromRef.current) {
      salesFromRef.current.value = "";
    }
    if (salesToRef.current) {
      salesToRef.current.value = "";
    }
  
    if (employeesNumFromRef.current) {
      employeesNumFromRef.current.value = "";
    }
    if (employeesNumToRef.current) {
      employeesNumToRef.current.value = "";
    }
  
    if (establishedFromRef.current) {
      establishedFromRef.current.value = "";
    }
    if (establishedToRef.current) {
      establishedToRef.current.value = "";
    }
  
    if (capitalFromRef.current) {
      capitalFromRef.current.value = "";
    }
    if (capitalToRef.current) {
      capitalToRef.current.value = "";
    }
    
    setBusinessContentsSearchMethod("AND");
    
    setCheckedHaveOrNotHaveHomePage([true,true]);
    setCheckedHaveOrNotHaveContactPage([true,true]);
    setCheckedListedOrUnListedCompany([true,true]);
    setCheckedCloseOrOpenCompany([false,true]);
    setCheckedOutputOrNotOutputCompany([true,true]);
    
    isDetailConditionsCheckboxDifferentFromDefaultState.current = false;

    setAccordionOpenManage([]);
    
    setIsEnteredDetailConditions(false);
    
  }
  
  function mappingNamesToCodes(saveSearchData:SearchData) {
    if (saveSearchData.industryCode) {
      const newForNotationIndustryItems:{[key:string]:string} = {};
      const industryCodeList = saveSearchData.industryCode.split(";");
      const industryNameList = saveSearchData.industry.split(";");
      
      if (industryCodeList.length > 0 && industryCodeList.length === industryNameList.length) {
        for(let i = 0; i < industryCodeList.length; i++) {
          newForNotationIndustryItems[industryCodeList[i]] = industryNameList[i];
        }
      }
      setForNotationIndustryItems(newForNotationIndustryItems);
    } 
    
    
    if (saveSearchData.addressCode) {
      const newForNotationRegionItems:{[key:string]:string} = {};
      
      const regionCodeList = saveSearchData.addressCode.split(";");
      const regionNameList = saveSearchData.address.split(";");
      
      if (regionCodeList.length > 0 && regionCodeList.length === regionNameList.length) {
        for(let i = 0; i < regionCodeList.length; i++) {
          newForNotationRegionItems[regionCodeList[i]] = regionNameList[i];
        }
      }
      setForNotationRegionItems(newForNotationRegionItems);
    }
  }  
    
  //検索条件呼び出し
  useEffect(() => {
    if (!callSearchModalIsOpen && callSearchData) {
      setReturnedCallSearchData(callSearchData[0]); // 一つだけ返却するケースを想定
    }
  }, [callSearchModalIsOpen]);
  
  function openCallSearchModal() {
    if (isProcessing) {
        return;
    }
    // 検索条件呼出モーダル表示処理開始時すべてのエラーメッセージ、成功メッセージをリセットする
    setSuccessMessage("");
    setErrorMessage1("");
    setErrorMessage2("");
    setErrorMessage3("");
    
    // 2重クリック防止
    setIsProcessing(true);
    
    if(tokenInfo.plan == "basic"){
      setCallSearchModalIsOpen(true);
      // 2重クリック防止解除
      setIsProcessing(false);
    }else{
      setErrorMessage2("現在のプランでは検索条件を保存できないため、呼び出し機能も利用できません。ベーシックプランへのアップグレードが必要です。")
      setIsNeedChangePlan(true);
      // 2重クリック防止解除
      setIsProcessing(false);
    }
  }
  function closeCallSearchModal(calledData: SearchData | null = null) {
    if (calledData) {
      setCallSearchData([calledData]);
    }
    setCallSearchModalIsOpen(false);
    }
  

  
  
  // 検索ボタンクリック時にモダール表示・CSV用の検索条件を取得・セットする関数
  // UI設計書と異なる
  function getSearchFilter(){
    // 選択された検索条件の取得・加工
    const industorialClass = Object.values(forNotationIndustryItems).join(';'); // 業種
    const address = Object.values(forNotationRegionItems).join(';'); // 地域
    const numberOfCorporate = dataCount // 企業数
    // 加工した検索条件をモーダルの引数にセット
    const csvDlSearchFilterRecord : SearchFilter  = {
            corporateName:corporateNameRef.current?.value ? corporateNameRef.current?.value : "",
            industorialClass:industorialClass,
            address:address,
            detailParameter:formatDetailRequirementsForSaveSearchData(),
            numberOfCorporate:numberOfCorporate,
            comments:'',
    }
    setSearchFilterRecord(csvDlSearchFilterRecord)
  }
  
  
  // CSVダウンロードモーダ表示関数（引数を検索条件保存インターフェースとする）
  function openCsvDlModal() {
    // 2重クリック防止・ダウンロード中ならば表示不能
    if (isProcessing || isDownload) {
      return;
    }
    // チェックした企業をCSVダウンロードモーダル表示処理開始時すべてのエラーメッセージ、成功メッセージをリセットする
    setSuccessMessage("");
    setErrorMessage1("");
    setErrorMessage2("");
    setErrorMessage3("");

    // 検索結果で選択した企業の法人番号リストを作成
    const selectedList = getSelectedCorporateNumberList();

    // 選択件数が0件なら中断してメッセージ表示
    if (selectedList.length == 0){
      setErrorMessage3('ダウンロードを行うには、少なくとも1件選択してください。');
      return;
    }
    
    // 2重クリック防止
    setIsProcessing(true);
    
    // 選択した企業数を検索条件レコードに入れる
    searchFilterRecord.numberOfCorporate = selectedList.length
    
    // モーダルの引数にセット
    setSelectedCorporateNumberList(selectedList);
    setSearchFilterRecord(searchFilterRecord);
    
    // CSVダウンロードモーダル表示フラグON
    setCsvDLModalIsOpen(true);
    // 2重クリック防止解除
    setIsProcessing(false);
  }
  
  // CSVダウンロードモーダ非表示関数
  function closeCsvDlModal() {
    setCsvDLModalIsOpen(false);
  }
  
  // CSVダウンロード関数
  function DownloadCsv(searchFilterRecord : SearchFilter, corporateNumberList:string[]) {
    // モーダルから検索条件・法人番号リストを受け取りセット
    setSearchFilterRecord(searchFilterRecord)
    setSelectedCorporateNumberList(corporateNumberList)
    // ダウンロードフラグON
    setIsDownload(true)
  }
  
  // CSVダウンロード（ダウンロードフラグONに変化した時実行）
  useEffect(() => {
    const downloadCsv = async () => {
      if(isDownload == true){
        // API実行　CSVファイルのダウンロード（ボタン押）
        // 注意：返り値はCSVデータでなく、CSVを保存したS3の署名付きURL
        const csvUrl = await callEventDownloadCsv(searchFilterRecord, selectedCorporateNumberList);
        // URL先をダウンロードさせる（非表示aタグをクリック）
        const link = document.createElement('a');
        link.href = csvUrl;
        link.download = ''; // ファイル名を指定（空の場合はデフォルトの名前）
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
        // 完了メッセージ
        setSuccessMessage('ダウンロードが完了しました。');
        setErrorMessage1("");
        setErrorMessage2("");
        setErrorMessage3("");
        setIsNeedChangePlan(false);
        setIsNeedAddPurchase(false);
        setIsNeedChangePlan2(false);
        alert('ダウンロードが完了しました。');
        window.location.reload(); //画面再読込
      }
    }
    downloadCsv();
  
  },[isDownload]);// isDownloadがtrueの場合のみ実行
  
  // API実行　CSVファイルのダウンロード（ボタン押）
  // 注意：実際の返り値はCSVデータでなく、CSVを保存したS3のURL
  const callEventDownloadCsv = async (searchFilterRecord:SearchFilter, corporateNumberList:string[]) => {
    try {
      // idTokenを取得
      const { idToken } = await getTokens();
      // API Gatewayを呼び出す
      const baseUrl:string = process.env.REACT_APP_API_BASE_URL || '';
      const path:string = '/event/csvdl';
      const apipath = baseUrl.concat(path);
      const response = await fetch(apipath,{
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${idToken}`, // IDトークンをヘッダーに設定
          'Content-Type': 'application/json'
        },
        body:JSON.stringify({
          'corporateNumberList':corporateNumberList, // 選択した企業の法人番号リスト
          'searchFilterRecord':searchFilterRecord // 検索条件レコード
        }),
      });
      // ステータスコードの確認
      if (!response.ok) {
        setErrorMessage1(ERROR_MESSAGE_PROCESSING_ERROR); // エラーメッセージを表示
        throw Error(`HTTP error! status: ${response.status}`); // 処理を中断
      }
      // レスポンスのJSONデータをパース
      const responseData = await response.json();

      // レスポンスのメッセージが空でなければ例外扱い
      if (responseData.message != ''){
        setErrorMessage3(responseData.message); // エラーメッセージを表示
        throw Error(responseData.message); // 処理を中断
      }

      // 正常ならばCSVを保存したS3のURLを返す
      // 2重ダウンロード防止解除
      setIsDownload(false);
      return responseData.url

    } catch (error) {
      console.error("callEventDownloadCsvエラー:", error);
      // 2重ダウンロード防止解除
      setIsDownload(false);
      throw error;
    }
  };
  
  // 検索結果変更の度にチェックボックスの初期化
  useMemo(() => {
    setMasterChecked(true);
    setOtherChecked(new Array(corporateNumList.length).fill(true)); // 検索結果数分のチェックボックスを作成
  }, [corporateNumList]);
  
  // 全て選択するショートカット
  const handleMasterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setMasterChecked(isChecked);
    setOtherChecked(otherChecked.map(() => isChecked));
  };
  
  // 個別のチェックボックス処理
  const handleOtherChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newChecked = otherChecked.slice();
    newChecked[index] = event.target.checked;
    setOtherChecked(newChecked);
  };

  // 選択済みの企業の法人番号リスト取得関数
  function getSelectedCorporateNumberList(): string[] {
    const checkedNumber = corporateNumList.filter((_, index) => otherChecked[index]);
    return checkedNumber;
  }
  
 // ■■ G-Search連携呼び出し
  const callGsearchService = async (record:GsearchServiceType) => {
    const newWindow = window.open('', '_blank');
    try {
      // idTokenを取得
      const { idToken } = await getTokens();
  
      // API Gatewayを呼び出す
      const baseUrl:string = process.env.REACT_APP_API_BASE_URL || '';
      const path:string = '/common/gsearch';
      const apipath = baseUrl.concat(path);
      const response = await fetch(apipath,{
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${idToken}`, // IDトークンをヘッダーに設定
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(record)
      });
      if (response.ok) {
        const result = await response.json();
        if (newWindow) newWindow.location.href = result.headers.Location
        return;
      } else {
        newWindow?.close();
        setErrorMessage3(ERROR_MESSAGE_PROCESSING_ERROR);
      }
    } catch (error) {
      newWindow?.close();
      console.error("callGsearchService:", error);
      setErrorMessage3(ERROR_MESSAGE_PROCESSING_ERROR);
      throw Error;
    }
  };

  //企業横断ボタン押下時
  function callKigyoOdan(corporateName:string) {
    // 企業横断ボタン押下時すべてのエラーメッセージ、成功メッセージをリセットする
    setSuccessMessage("");
    setErrorMessage1("");
    setErrorMessage2("");
    setErrorMessage3("");
    
    const dbid = "RXCC"
    const now = getCurrentTimestamp()
    
    const Args: GsearchServiceType = {
      corporateName: corporateName,
      dbid: dbid,
      T: now,
    };
    if(tokenInfo.plan == "free"){
      navigate("/planchange");
    }else{
      if(tokenInfo.available_features == "disabled"){
        //エラーメッセージを表示して中断
        setErrorMessage3("ただいまお客様のアカウントではG-Searchコンテンツをご利用いただけません。詳細については、メールをご確認ください。");
        return
      }
      callGsearchService(Args)
    }
  };
  //新聞横断ボタン押下時
  function callShinbunOdan(corporateName:string) {
    // 新聞横断ボタン押下時すべてのエラーメッセージ、成功メッセージをリセットする
    setSuccessMessage("");
    setErrorMessage1("");
    setErrorMessage2("");
    setErrorMessage3("");
    const dbid = "RXCN"
    const now = getCurrentTimestamp()
    
    const Args: GsearchServiceType = {
      corporateName: corporateName,
      dbid: dbid,
      T: now,
    };
    if(tokenInfo.plan == "free"){
      navigate("/planchange");
    }else{
      if(tokenInfo.available_features == "disabled"){
        //エラーメッセージを表示して中断
        setErrorMessage3("ただいまお客様のアカウントではG-Searchコンテンツをご利用いただけません。詳細については、メールをご確認ください。");
        return
      }
      callGsearchService(Args)
    }
  };
  
  //現在時刻取得(YYYYmmddhhmmddに整形)
  function getCurrentTimestamp() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // 月は0始まりなので+1します
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
  
    return `${year}${month}${day}${hours}${minutes}${seconds}`;
  }
  
  //現在時刻取得(YYYY/mmに変形)
  function getFormattedTimeShort() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');  // 月は0から始まるので+1する
    return `${year}/${month}`;
  }
  
  //現在時刻取得(yyyy-MM-dd hh:mm:ssに変形)
  function getFormattedTimeLong() {
    const now = new Date();
    const year = now.getFullYear();
    const month = ('0' + (now.getMonth() + 1)).slice(-2);
    const day = ('0' + now.getDate()).slice(-2);
    const hours = ('0' + now.getHours()).slice(-2);
    const minutes = ('0' + now.getMinutes()).slice(-2);
    const seconds = ('0' + now.getSeconds()).slice(-2);

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  
  //企業名リンク押下時処理
  async function callCompanyDetail(corporateNumber:string) {
    // 企業名リンク押下時すべてのエラーメッセージ、成功メッセージをリセットする
    setSuccessMessage("");
    setErrorMessage1("");
    setErrorMessage2("");
    setErrorMessage3("");
    const nowShort = getFormattedTimeShort(); //YYYY/mm
    const nowLong = getFormattedTimeLong(); //yyyy-MM-dd hh:mm:ss
    try {
      //残数上限チェック
      //isNotDownloadableがTrueの時ダウンロードできない。
      const isNotDownloadable =await callCheckLimitoutput(nowShort,corporateNumber);
      if(isNotDownloadable && tokenInfo.plan == 'basic'){
        setErrorMessage3("ダウンロード/表示件数が不足しています。")
        setIsNeedAddPurchase(true)
        return
      }else if(isNotDownloadable && (tokenInfo.plan == 'free' || tokenInfo.plan == 'freePlus')){
        setErrorMessage3("ダウンロード/表示件数が不足しています。出力数追加には、ベーシックプランへのアップグレードが必要です。")
        setIsNeedChangePlan2(true)
        return
      }
      //企業詳細画面へ遷移
      navigate(`corporatedetail?corporateNumber=${corporateNumber}`);
      
    } catch (error) {
      setErrorMessage1(ERROR_MESSAGE_PROCESSING_ERROR);
      } 
    }
  
  
  // ■■ 残数上限チェックAPI呼び出し
  const callCheckLimitoutput = async (now:string, corporateNumber:string) => {
    try {
      // idTokenを取得
      const { idToken } = await getTokens();
  
      // API Gatewayを呼び出す
      const baseUrl:string = process.env.REACT_APP_API_BASE_URL || '';
      const path:string = '/common/checklimitoutput';
      const apipath = baseUrl.concat(path);
      const response = await fetch(apipath,{
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${idToken}`, // IDトークンをヘッダーに設定
          'Content-Type': 'application/json'
        },
        body:JSON.stringify({
          'outputDate':now, 
          'corporateNumber':[corporateNumber],
          'numberOfCorporate':'1',
          'targetDate':now 
        }),
      });
      // レスポンスのJSONデータをパース
      const responseData = await response.json();
      // レスポンスのbodyから必要なデータを取得
      const { isNotDownloadable } = responseData;
      return isNotDownloadable;
    } catch (error) {
      console.error("callCheckLimitoutput:", error);
      throw Error;
    }
  };
  
  
  // ■■ 企業詳細への遷移API呼び出し
  const callEventLnkcompanydetail = async (nowShort:string, corporateNumber:string, outputCount_use:string, nowLong:string) => {
    try {
      // idTokenを取得
      const { idToken } = await getTokens();
  
      // API Gatewayを呼び出す
      const baseUrl:string = process.env.REACT_APP_API_BASE_URL || '';
      const path:string = '/event/link';
      const apipath = baseUrl.concat(path);
      const response = await fetch(apipath,{
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${idToken}`, // IDトークンをヘッダーに設定
          'Content-Type': 'application/json'
        },
        body:JSON.stringify({
          'outputCorporates': [{
                               'corporateNumber': corporateNumber,
                               'outputDate': nowShort,
                               'created_at': nowLong,
                               'updated_at': nowLong
                              }],
          'outputCount_use': outputCount_use,
          'updatedAt': nowLong,
          'outputDate': nowShort, 
          'targetDate': nowShort 
        }),
      });
    } catch (error) {
      console.error("callEventLnkcompanydetail:", error);
      throw Error;
    }
  };
  
  // ■■ 残数レコード取得API呼び出し
  const callGetRecordOutputmanage = async (targetDate:string) => {
    try {
      // idTokenを取得
      const { idToken } = await getTokens();
  
      // API Gatewayを呼び出す
      const baseUrl:string = process.env.REACT_APP_API_BASE_URL || '';
      const path:string = '/postgres/getoutputmanage';
      const apipath = `${baseUrl}${path}?targetDate=${encodeURIComponent(targetDate)}`;
      const response = await fetch(apipath,{
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${idToken}`, // IDトークンをヘッダーに設定
          'Content-Type': 'application/json'
        },
      });
      // レスポンスのJSONデータをパース
      const responseData = await response.json();
      // レスポンスのbodyから必要なデータを取得
      const { outputCount_use } = responseData;
      return outputCount_use;
    } catch (error) {
      console.error("callGetRecordOutputmanage:", error);
      throw Error;
    }
  };
  
  useEffect(() => {
    saveState();
  }, [rowsPerPage, sortKey, currentPage, ascDesc, dataCount, tableData]);

  //検索条件セット処理
  const handleConditionSet = () => {
    //企業名
    if(corporateNameRef.current){
      setCorporateName(corporateNameRef.current.value);
    }
    //事業内容１
    if(businessContentsFirstRef.current){
      setBusinessContentsFirst(businessContentsFirstRef.current.value);
    }
    //事業内容２
    if(businessContentsSecondRef.current){
      setBusinessContentsSecond(businessContentsSecondRef.current.value);
    }
    //事業内容３
    if(businessContentsThirdRef.current){
      setBusinessContentsThird(businessContentsThirdRef.current.value);
    }
    //事業内容４
    if(businessContentsFourthRef.current){
      setBusinessContentsFourth(businessContentsFourthRef.current.value);
    }
    //事業内容５
    if(businessContentsFifthRef.current){
      setBusinessContentsFifth(businessContentsFifthRef.current.value);
    }
    //売上From
    if(salesFromRef.current){
      setSalesFrom(salesFromRef.current.value);
    }
    //売上To
    if(salesToRef.current){
      setSalesTo(salesToRef.current.value);
    }
    //従業員数From
    if(employeesNumFromRef.current){
      setEmployeesNumFrom(employeesNumFromRef.current.value);
    }
    //従業員数To
    if(employeesNumToRef.current){
      setEmployeesNumTo(employeesNumToRef.current.value);
    }
    //設立年From
    if(establishedFromRef.current){
      setEstablishedFrom(establishedFromRef.current.value);
    }
    //設立年To
    if(establishedToRef.current){
      setEstablishedTo(establishedToRef.current.value);
    }
    //資本金From
    if(capitalFromRef.current){
      setCapitalFrom(capitalFromRef.current.value);
    }
    //資本金To
    if(capitalFromRef.current){
      setCapitalFrom(capitalFromRef.current.value);
    }
  };
  
  //検索時に保存
  //awaitを付けないとsessionStorageからデータが消えてしまう。
  //最初にセットするItemがロード時、空で取得されてしまうため(dummy)で対応
  const saveState = async () => {
    await sessionStorage.setItem('dummy', dummy);
    await sessionStorage.setItem('forNotationIndustryItems', JSON.stringify(forNotationIndustryItems));
    await sessionStorage.setItem('checkedIndustryItems', JSON.stringify(checkedIndustryItems));
    await sessionStorage.setItem('forNotationRegionItems', JSON.stringify(forNotationRegionItems));
    await sessionStorage.setItem('checkedRegionItems', JSON.stringify(checkedRegionItems));
    await sessionStorage.setItem('corporateName', corporateName);
    
    // 検索条件
    await sessionStorage.setItem('businessContentsSearchMethod',businessContentsSearchMethod);
    await sessionStorage.setItem('businessContentsFirst',businessContentsFirst);
    await sessionStorage.setItem('businessContentsSecond',businessContentsSecond);
    await sessionStorage.setItem('businessContentsThird',businessContentsThird);
    await sessionStorage.setItem('businessContentsFourth',businessContentsFourth);
    await sessionStorage.setItem('businessContentsFifth',businessContentsFifth);
    await sessionStorage.setItem('salesFrom',salesFrom);
    await sessionStorage.setItem('salesTo',salesTo);
    await sessionStorage.setItem('employeesNumFrom',employeesNumFrom);
    await sessionStorage.setItem('employeesNumTo',employeesNumTo);
    await sessionStorage.setItem('establishedFrom',establishedFrom);
    await sessionStorage.setItem('establishedTo',establishedTo);
    await sessionStorage.setItem('capitalFrom',capitalFrom);
    await sessionStorage.setItem('capitalTo',capitalTo);
    
    await sessionStorage.setItem('checkedHaveOrNotHaveHomePage', JSON.stringify(checkedHaveOrNotHaveHomePage));
  　await sessionStorage.setItem('checkedHaveOrNotHaveContactPage', JSON.stringify(checkedHaveOrNotHaveContactPage));
    await sessionStorage.setItem('checkedListedOrUnListedCompany', JSON.stringify(checkedListedOrUnListedCompany));
    await sessionStorage.setItem('checkedCloseOrOpenCompany', JSON.stringify(checkedCloseOrOpenCompany));
    await sessionStorage.setItem('checkedOutputOrNotOutputCompany', JSON.stringify(checkedOutputOrNotOutputCompany));
    
    await sessionStorage.setItem('currentResultSearchConditions', JSON.stringify(currentResultSearchConditions)); 
    
    // ページネーション・並び替え情報
    await sessionStorage.setItem('currentPage', JSON.stringify(currentPage));
    await sessionStorage.setItem('rowsPerPage', JSON.stringify(rowsPerPage));
    await sessionStorage.setItem('sortKey', sortKey);
    await sessionStorage.setItem('ascDesc', ascDesc);
    await sessionStorage.setItem('isAscendingPush', JSON.stringify(isAscendingPush));
    
    // 検索結果の表示内容
    await sessionStorage.setItem('dataCount', JSON.stringify(dataCount));
    await sessionStorage.setItem('tableData', JSON.stringify(tableData));
    await sessionStorage.setItem('isSearchClicked', JSON.stringify(isSearchClicked));
    await sessionStorage.setItem('corporateNumList', JSON.stringify(corporateNumList));
    
    // CSVDL条件　チェックボックス
    await sessionStorage.setItem('searchFilterRecord', JSON.stringify(searchFilterRecord));
    await sessionStorage.setItem('masterChecked', JSON.stringify(masterChecked)); 
    await sessionStorage.setItem('otherChecked', JSON.stringify(otherChecked)); 
    
  };
  
  useEffect(() => {
    if (errorMessage1 && error1Ref.current) {
      error1Ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    else if (errorMessage2 && error2Ref.current) {
      error2Ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    else if (errorMessage3 && error3Ref.current) {
      error3Ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    else if (successMessage && successRef.current) {
      successRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [errorMessage1,errorMessage2,errorMessage3,successMessage]);
  
  // プラン変更画面へ遷移
  const handleNavigatePlanChange = () => {
      navigate("/planchange");
  };
  
  // 追加購入画面へ遷移
  const handleNavigateAddPurchase = () => {
      navigate("/addpurchase");
  };
  
  // 並び替えプルダウン項目
  const [options, setOptions] = useState<JSX.Element[]>([]);
  const breakpoint = useBreakpointValue({ base: 'base', large: 'large' });
  useEffect(() => {
    if (breakpoint === 'base') {
      setOptions([
        <option value="20">20件</option>
      ]);
    } else {
      setOptions([
        <option value="20">20件</option>,
        <option value="50">50件</option>,
        <option value="100">100件</option>,
        <option value="200">200件</option>
      ]);
    }
  }, [breakpoint]);
  
  
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  
  useEffect(() => {
  const handleResize = () => {
    setWindowDimensions({
      width: window.innerWidth,
      height: window.innerHeight
    });
  };

  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);
  
  //省略時の文字数設定
  useEffect(() => {
    if (windowDimensions.width < 1100) {
      setMinWidth('1450px');
    } else {
      setMinWidth('950px');
    }
  }, [windowDimensions]);
  
//テーブルの横幅設定
const [minWidth, setMinWidth] = useState('1150px');


// infomationボタンを別タブで開く
const handleOpenCreditNews = () => {
  window.open(`${process.env.REACT_APP_OPENSITE_BASE_URL}/download/guide/cnavi-guide-credit-news.pdf`, "_blank", "noreferrer");
};   

  return (
    <>
      <Helmet>
        <title>企業INDEXナビ</title>
      </Helmet>
      <View className="appContainer">
      <Header setIsLoadedHeader={setIsLoadedHeader}/>
      <Flex className="mainArea" direction="column" alignItems="center" padding="1rem" backgroundColor={tokens.colors.blue[10]}>
        {/*PC版*/}
        <View width="100%">
          <Flex direction="column" alignItems="center" width="100%" maxWidth="1455px" margin="0 auto">
            
            
           {/* エラーメッセージの表示 */}
            {errorMessage1 && (
            <div ref={error1Ref} className="alertHome">
              <Alert
                isDismissible={false}
                variation="error"
                color="red"
                hasIcon={true}
                heading=""
                padding="1rem"
              >
                {errorMessage1}
              </Alert>
            </div>
            )}
  
            {/* お知らせとデータ更新セクション */}
            <Accordion.Container 
                width="100%"
                margin="50px 0"
                backgroundColor={tokens.colors.white}
            >
                <Accordion.Item value="Accordion-item" fontSize={tokens.fontSizes.medium} fontWeight={tokens.fontWeights.bold} borderColor={tokens.colors.blue[60]} borderWidth="2px" borderRadius="10px">
                    <Accordion.Trigger className="HomeInfoAccordion" padding={{ base: "5px 20px", large: "10px 20px" }} color={tokens.colors.black} backgroundColor={tokens.colors.white} boxShadow="none" borderRadius="10px">
                        <View as="span" fontSize={{base: tokens.fontSizes.xl, large: tokens.fontSizes.xxl}}>お知らせ/データ更新</View>
                        <IoMdArrowDropdown size="40px" className="amplify-accordion__item__icon"/>
                    </Accordion.Trigger>
                    <Accordion.Content className="accordion-content__notice-info" style={{borderTop:"1px dashed hsl(210, 10%, 58%)"}}>
                        <Flex padding="15px 20px" direction={{ base: "column", large: "row" }} justifyContent="space-between" >
                            <View as="div" width={{ base: "90%", large: "45%" }}>
                                <Text
                                    fontSize={{base: tokens.fontSizes.xl, large: tokens.fontSizes.xxl}}
                                    fontWeight={tokens.fontWeights.bold}
                                    marginBottom="15px"
                                >
                                    お知らせ
                                </Text>
                                <View className="homeinfo-htmlcontent" color={tokens.colors.font.primary} dangerouslySetInnerHTML={{ __html: homeInfoHtmlContent }} />
                            </View>
                            <Divider borderStyle="dotted" padding="0 5%" display={{ base: "block", large: "none" }}/>
                            <View as="div" width={{ base: "90%", large: "45%" }}>
                                <Text
                                    fontSize={{base: tokens.fontSizes.xl, large: tokens.fontSizes.xxl}}
                                    fontWeight={tokens.fontWeights.bold}
                                    marginBottom="15px"
                                >
                                    データ更新
                                </Text>
                                {updatedDataList.map((item:string, index) => (
                                  <Text key={index} marginBottom="10px" fontSize={{base: tokens.fontSizes.large, large: tokens.fontSizes.xl}} fontWeight={tokens.fontWeights.medium}>{item.replace(/"/g, '')}</Text>
                                ))}
                            </View>
                        </Flex>
                    </Accordion.Content>
                </Accordion.Item>
            </Accordion.Container>
            
            {/* 企業検索セクション */}
            <Flex direction="column" width="100%" gap="0">
              <Card width="100%" marginBottom="0" padding={{ base: "1rem 2rem", large: "0 3rem" }} backgroundColor={tokens.colors.blue[60]} borderRadius="1rem 1rem 0 0">
                <Flex direction="row" alignItems="center" justifyContent="space-between" backgroundColor={tokens.colors.blue[60]}>
                  <Flex direction={{ base: "column-reverse", large: "row" }} alignItems="center" gap={{ base: "0.2rem", large: "1rem" }}>
                    <Image src={IcoSearchTitle} alt="企業検索ロゴ" width={{ base: "50px", large: "80px" }} height={{ base: "50px", large: "80px" }} />
                    <Text fontWeight="bold" fontSize={{ base: tokens.fontSizes.xxl, large: tokens.fontSizes.xxxl }} color="white">企業検索</Text>
                  </Flex>
                  <Flex direction={{ base: "column", large: "row" }} gap="1rem">
                    <Button className="whiteToBlueHoverButton" width="160px" height="40px" borderRadius="20px" backgroundColor={tokens.colors.white} color={tokens.colors.blue[90]} borderColor={tokens.colors.blue[90]}　onClick={() => openSaveSearchModal()} isDisabled={isLoadingToken}>
                      <img src={IcoSearchSave} height={"15px"} width={"15px"}/>
                      <View as="span" fontSize={tokens.fontSizes.large} marginLeft="10px" marginTop="2px">検索条件を保存</View>
                    </Button>
                    <Button className="whiteToBlueHoverButton" width="160px" height="40px" borderRadius="20px" backgroundColor={tokens.colors.white} color={tokens.colors.blue[90]} borderColor={tokens.colors.blue[90]}  onClick={() => openCallSearchModal()} isDisabled={isLoadingToken}>
                      <img src={IcoSearchLoad} height={"15px"} width={"15px"}/>
                      <View as="span" fontSize={tokens.fontSizes.large} marginLeft="7.5px" marginTop="2px">検索条件の呼び出し</View>
                    </Button>
                  </Flex>
                </Flex>
              </Card>
              
              <Card width="100%" marginTop="0" padding="0" backgroundColor={tokens.colors.white} borderRadius="0 0 1rem 1rem">
                  <Flex direction="row" justifyContent="space-between" >
                    
                    {/* 業種・地域・企業名・詳細条件の入力エリア */}
                    
                    <Flex direction="column" width="100%" gap="0">
                      {/* 業種 */}
                      <Accordion.Container value={accordionOpenManage} onValueChange={(value: string[] | undefined) => setAccordionOpenManage(value)} >
                        <Accordion.Item value="industry" borderColor={tokens.colors.blue[60]} borderWidth="2px" borderRadius="0" >
                            <Accordion.Trigger className="SearchFilterAccordion" backgroundColor={tokens.colors.white} padding="0" height="50px" lineHeight="50px" paddingLeft={{base:"15px",large:"20px"}} boxShadow="none" paddingRight={{base:"5px",large:"15px"}}> 
                                <Flex >
                                    <Text fontSize={tokens.fontSizes.large} fontWeight={tokens.fontWeights.bold} width="120px"><View as="span" fontSize={{base:tokens.fontSizes.xl,large:tokens.fontSizes.xxl}} style={{marginRight:"2px"}}>業種</View>で絞る</Text>
                                    {Object.keys(checkedIndustryItems).length > 0 ?　<img src={IcoFilterOn} height={"25px"} width={"25px"}/> : <img src={IcoFilterOff} height={"25px"} width={"25px"}/>}
                                </Flex>
                                <IoMdArrowDropdown size="40px" className="amplify-accordion__item__icon"/>
                            </Accordion.Trigger>
                            <Accordion.Content backgroundColor={tokens.colors.blue[10]} maxHeight="350px" paddingBottom="20px" style={{overflowX:"hidden",overflowY:"scroll"}}> 
                                <SearchFilterCheckboxInput 
                                  dataDict={industryData} 
                                  checkedItems={checkedIndustryItems} 
                                  setCheckedItems={setCheckedIndustryItems} 
                                  forNotationItems={forNotationIndustryItems} 
                                  setForNotationItems={setForNotationIndustryItems} 
                                  sortingList={[]} 
                                  upperLevelItemCodeConcatString={undefined}
                                  upperLevelItemNameConcatString={undefined}
                                  eachItemNum={eachIndustrialCompanyNum}
                                  isTopLevelComponent={true}
                                  isRegionClassification={false}
                                />
                            </Accordion.Content>
                        </Accordion.Item>
                    </Accordion.Container>
              
                      {/* 地域 */}
                      <Accordion.Container value={accordionOpenManage} onValueChange={(value: string[] | undefined) => setAccordionOpenManage(value)} >
                        <Accordion.Item value="region" borderColor={tokens.colors.blue[60]} borderRadius="0" borderWidth="2px" style={{borderTop:"none"}}>
                            <Accordion.Trigger className="SearchFilterAccordion" backgroundColor={tokens.colors.white} padding="0" height="50px" lineHeight="50px" paddingLeft={{base:"15px",large:"20px"}} boxShadow="none" paddingRight={{base:"5px",large:"15px"}}> 
                                <Flex >
                                    <Text fontSize={tokens.fontSizes.large} fontWeight={tokens.fontWeights.bold} width="120px"><View as="span" fontSize={{base:tokens.fontSizes.xl,large:tokens.fontSizes.xxl}} style={{marginRight:"2px"}}>地域</View>で絞る</Text>
                                    {Object.keys(checkedRegionItems).length > 0 ?　<img src={IcoFilterOn} height={"25px"} width={"25px"}/> : <img src={IcoFilterOff} height={"25px"} width={"25px"} />}
                                </Flex>
                                <IoMdArrowDropdown size="40px" className="amplify-accordion__item__icon"/>
                            </Accordion.Trigger>
                            <Accordion.Content backgroundColor={tokens.colors.blue[10]} maxHeight="350px" paddingBottom="20px" style={{overflowX:"hidden",overflowY:"scroll"}}> 
                                <Flex marginLeft={{base:"16px",large:"32.5px"}} direction="column" gap="0">
                                  {Object.entries(regionClassificationDataDict).map(([key,value]) => 
                                    <>
                                      <Flex as="button" className="plusMinusDisplaySwitchButton" tabIndex={0} direction="row" marginTop="15px" gap="8px" onClick={() => handleClassificationCheck(key,regionData)} style={{cursor:"pointer"}}>
                                        {openRegionClassificationItems[key] !== undefined && openRegionClassificationItems[key] ? 
                                          <img src={IcoMinus} height={"25px"} width={"25px"}/> 
                                          : 
                                          <img src={IcoPlus} height={"25px"} width={"25px"}/>
                                        }
                                        <Text fontSize={tokens.fontSizes.large} marginTop="4px">{key}</Text>
                                      </Flex>
                                      {openRegionClassificationItems[key] &&(
                                          <SearchFilterCheckboxInput 
                                              dataDict={regionData} 
                                              checkedItems={checkedRegionItems} 
                                              setCheckedItems={setCheckedRegionItems} 
                                              forNotationItems={forNotationRegionItems} 
                                              setForNotationItems={setForNotationRegionItems} 
                                              sortingList={value}
                                              upperLevelItemCodeConcatString = {undefined}
                                              upperLevelItemNameConcatString = {undefined}
                                              eachItemNum={eachRegionCompanyNum}
                                              isTopLevelComponent={false}
                                              isRegionClassification={true}
                                          />
                                      )}
                                    </>
                                )}
                              </Flex>
                            </Accordion.Content>
                        </Accordion.Item>
                    </Accordion.Container>
                      
                      {/* 企業名 */}
                      <Flex id="corporateNameInput" justifyContent="space-between" backgroundColor={tokens.colors.white} borderColor={tokens.colors.blue[60]} borderWidth="2px" height="50px" lineHeight="50px" paddingLeft={{base:"15px",large:"20px"}} paddingRight={{base:"10px",large:"20px"}} style={{borderBottom:"solid",borderRight:"solid",borderLeft:"solid",borderTop:"none"}}> 
                        <Text fontSize={tokens.fontSizes.large} width="120px" fontWeight={tokens.fontWeights.bold}><View as="span" fontSize={{base:tokens.fontSizes.xl,large:tokens.fontSizes.xxl}} style={{marginRight:"2px"}}>企業名</View>で検索</Text>
                        <TextField id="first_name" label="" placeholder="企業名を入力" width="90%" marginRight={{base:"0",large:"30px"}} height="40px" lineHeight="40px" inputStyles={{backgroundColor:`${tokens.colors.blue[10]}`,height:"40px",fontSize:`${tokens.fontSizes.xl}`}} ref={corporateNameRef} />
                      </Flex>
                      
                      {/* 詳細条件 */}
                          <Accordion.Container value={accordionOpenManage} onValueChange={(value: string[] | undefined) => setAccordionOpenManage(value)} >
                            <Accordion.Item value="details" borderColor={tokens.colors.blue[60]} borderRadius="0" borderWidth="2px" style={{borderTop:"none"}}>
                                <Accordion.Trigger className="SearchFilterAccordion" backgroundColor={tokens.colors.white} padding="0" height="50px" lineHeight="50px" paddingLeft={{base:"15px",large:"20px"}} boxShadow="none" paddingRight={{base:"5px",large:"15px"}}> 
                                    <Flex>
                                        <Text fontSize={tokens.fontSizes.large} fontWeight={tokens.fontWeights.bold} width="120px"><View as="span" fontSize={{base:tokens.fontSizes.xl,large:tokens.fontSizes.xxl}} style={{marginRight:"2px"}}>詳細条件</View>で絞る</Text>
                                        {isEnteredDetailConditions ?　<img src={IcoFilterOn} height={"25px"} width={"25px"}/> : <img src={IcoFilterOff} height={"25px"} width={"25px"} />}
                                        {!allowDetailConditionInput ? 
                                          <View as="div" className="notAllowDetailConditionInputIndication">
                                            <img src={IcoInfo} height={"20px"} width={"20px"}/>
                                            <View as="span" className="notAllowDetailConditionInputIndication__tooltip">ベーシックプランでご利用いただけます</View>
                                          </View>
                                          : 
                                          <></>
                                        }
                                    </Flex>
                                    <IoMdArrowDropdown size="40px" className="amplify-accordion__item__icon"/>
                                </Accordion.Trigger>
                                <Accordion.Content backgroundColor={tokens.colors.blue[10]} maxHeight="350px" paddingTop="15px" paddingBottom="20px" paddingLeft={{base:"15px",large:"50px"}} style={{overflowX:"hidden",overflowY:"scroll"}}> 
                                    <Flex direction="column" gap="0">
                                    <Flex direction="row" gap={{base:"0",large:"10px"}} justifyContent="flex-start">
                                      <View as="div" width={{base:"100px",large:"120px"}}>
                                        <Text fontSize={tokens.fontSizes.large} fontWeight={tokens.fontWeights.bold}  textAlign="left">事業内容</Text>
                                      </View>
                                      <View as="div" width="170px">
                                        <RadioGroupField
                                          legend=""
                                          name=""
                                          value={businessContentsSearchMethod}
                                          onChange={(e) => {
                                            setBusinessContentsSearchMethod(e.target.value);
                                            }
                                          }
                                          direction="row"
                                          size="large"
                                          className="detailConditionRadioButton"
                                          isDisabled={!allowDetailConditionInput}                                          
                                        >
                                          <Radio tabIndex={0} className="detailConditionRadioChoices" value="AND" width="20px" height="20px"><View as="span" marginRight={{base:"40px",large:"30px"}}>AND</View></Radio>
                                          <Radio tabIndex={0} className="detailConditionRadioChoices" value="OR" width="20px">OR</Radio>
                                        </RadioGroupField>
                                        
                                      </View>
                                    </Flex>
                                     <Flex direction="row" wrap="wrap" gap="0" marginLeft={{base:"0",large:"130px"}}>
                                          <TextField isDisabled={!allowDetailConditionInput} width={{base:"45%",large:"30%"}} marginTop="8px" marginRight={{base:"10px",large:"40px"}} inputStyles={{backgroundColor:`${tokens.colors.white}`,height:"40px",fontSize:`${tokens.fontSizes.large}`}} label="" placeholder="" name="businessContents" ref={businessContentsFirstRef}  onBlur={() => confirmDetailConditionsIsEnterd(false)}/>
                                          <TextField isDisabled={!allowDetailConditionInput} width={{base:"45%",large:"30%"}} marginTop="8px" marginRight={{base:"10px",large:"40px"}}  inputStyles={{backgroundColor:`${tokens.colors.white}`,height:"40px",fontSize:`${tokens.fontSizes.large}`}} label="" placeholder="" name="businessContents" ref={businessContentsSecondRef}  onBlur={() => confirmDetailConditionsIsEnterd(false)}/>
                                          <TextField isDisabled={!allowDetailConditionInput} width={{base:"45%",large:"30%"}} marginTop="8px" marginRight={{base:"10px",large:"40px"}} inputStyles={{backgroundColor:`${tokens.colors.white}`,height:"40px",fontSize:`${tokens.fontSizes.large}`}} label="" placeholder="" name="businessContents" ref={businessContentsThirdRef}  onBlur={() => confirmDetailConditionsIsEnterd(false)}/>
                                          <TextField isDisabled={!allowDetailConditionInput} width={{base:"45%",large:"30%"}} marginTop="8px" marginRight={{base:"10px",large:"40px"}} inputStyles={{backgroundColor:`${tokens.colors.white}`,height:"40px",fontSize:`${tokens.fontSizes.large}`}} label="" placeholder="" name="businessContents" ref={businessContentsFourthRef}  onBlur={() => confirmDetailConditionsIsEnterd(false)}/>
                                          <TextField isDisabled={!allowDetailConditionInput} width={{base:"45%",large:"30%"}} marginTop="8px" marginRight={{base:"10px",large:"40px"}} inputStyles={{backgroundColor:`${tokens.colors.white}`,height:"40px",fontSize:`${tokens.fontSizes.large}`}} label="" placeholder="" name="businessContents" ref={businessContentsFifthRef}  onBlur={() => confirmDetailConditionsIsEnterd(false)}/>
                                    </Flex>
                                    
                                    <Flex direction={{base:"column",large:"row"}} gap={{base:"0",large:"10px"}} style={{verticalAlign:"center"}} marginTop="15px">
                                      <View as="div" width="120px" textAlign="center" lineHeight="40px">
                                        <Text fontSize={tokens.fontSizes.large} fontWeight={tokens.fontWeights.bold} textAlign="left">売上</Text>
                                      </View>
                                      <View as="div" width={{base:"100%",large:"90%"}}>
                                        <Flex direction="row" gap={{base:"0.5rem",large:"1rem"}} lineHeight="40px" >
                                          <TextField isDisabled={!allowDetailConditionInput} inputStyles={{backgroundColor:`${tokens.colors.white}`,fontSize:`${tokens.fontSizes.large}`,width:"120px",height:"40px"}} label="" placeholder="" ref={salesFromRef}  fontSize={tokens.fontSizes.medium} gap="0" onBlur={() => confirmDetailConditionsIsEnterd(false)}/>
                                          <Flex justifyContent="space-between" gap="0" width={{base:"40px",large:"60px"}} fontSize={tokens.fontSizes.large}>
                                            <Text>万円</Text>
                                            <Text>～</Text>
                                          </Flex>
                                          <TextField isDisabled={!allowDetailConditionInput} inputStyles={{backgroundColor:`${tokens.colors.white}`,fontSize:`${tokens.fontSizes.large}`,width:"120px",height:"40px"}} label="" placeholder="" ref={salesToRef}  fontSize={tokens.fontSizes.medium} gap="0" onBlur={() => confirmDetailConditionsIsEnterd(false)}/>
                                          <Text fontSize={tokens.fontSizes.large}>万円</Text>
                                        </Flex>
                                      </View>
                                    </Flex>
                                    <Flex direction={{base:"column",large:"row"}} gap={{base:"0",large:"10px"}} style={{verticalAlign:"center"}} marginTop="15px"> 
                                      <View as="div" width="120px" textAlign="center" lineHeight="40px">
                                        <Text fontSize={tokens.fontSizes.large} fontWeight={tokens.fontWeights.bold}  textAlign="left">従業員数</Text>
                                      </View>
                                      <View as="div" width={{base:"100%",large:"90%"}}>
                                        <Flex direction="row" gap={{base:"0.5rem",large:"1rem"}} lineHeight="40px" >
                                          <TextField isDisabled={!allowDetailConditionInput} inputStyles={{backgroundColor:`${tokens.colors.white}`,fontSize:`${tokens.fontSizes.large}`,width:"120px",height:"40px"}}  label="" placeholder="" ref={employeesNumFromRef}  fontSize={tokens.fontSizes.medium} gap="0" onBlur={() => confirmDetailConditionsIsEnterd(false)}/>
                                          <Flex justifyContent="space-between" gap="0" width={{base:"40px",large:"60px"}} fontSize={tokens.fontSizes.large}>
                                            <Text>人</Text>
                                            <Text>～</Text>
                                          </Flex>
                                          <TextField isDisabled={!allowDetailConditionInput} inputStyles={{backgroundColor:`${tokens.colors.white}`,fontSize:`${tokens.fontSizes.large}`,width:"120px",height:"40px"}} label="" placeholder="" ref={employeesNumToRef}  fontSize={tokens.fontSizes.medium} gap="0" onBlur={() => confirmDetailConditionsIsEnterd(false)}/>
                                          <Text fontSize={tokens.fontSizes.large}>人</Text>
                                        </Flex>
                                      </View>
                                    </Flex>
                                    <Flex direction={{base:"column",large:"row"}} gap={{base:"0",large:"10px"}} style={{verticalAlign:"center"}} marginTop="15px"> 
                                      <View as="div" width="120px" textAlign="center" lineHeight="40px">
                                        <Text fontSize={tokens.fontSizes.large} fontWeight={tokens.fontWeights.bold}  textAlign="left">設立年</Text>
                                      </View>
                                      <View as="div" width={{base:"100%",large:"90%"}}>
                                        <Flex direction="row" gap={{base:"0.5rem",large:"1rem"}} lineHeight="40px" >
                                          <TextField isDisabled={!allowDetailConditionInput} inputStyles={{backgroundColor:`${tokens.colors.white}`,fontSize:`${tokens.fontSizes.large}`,width:"120px",height:"40px"}}  label="" placeholder=""  ref={establishedFromRef}  fontSize={tokens.fontSizes.medium} gap="0" onBlur={() => confirmDetailConditionsIsEnterd(false)}/>
                                          <Flex justifyContent="space-between" gap="0" width={{base:"40px",large:"60px"}} fontSize={tokens.fontSizes.large}>
                                            <Text>年</Text>
                                            <Text>～</Text>
                                          </Flex>
                                          <TextField isDisabled={!allowDetailConditionInput} inputStyles={{backgroundColor:`${tokens.colors.white}`,fontSize:`${tokens.fontSizes.large}`,width:"120px",height:"40px"}} label="" placeholder="" ref={establishedToRef}  fontSize={tokens.fontSizes.medium} gap="0" onBlur={() => confirmDetailConditionsIsEnterd(false)}/>
                                          <Text fontSize={tokens.fontSizes.large}>年</Text>
                                        </Flex>
                                      </View>
                                    </Flex>
                                    <Flex direction={{base:"column",large:"row"}} gap={{base:"0",large:"10px"}} style={{verticalAlign:"center"}} marginTop="15px">
                                      <View as="div" width="120px" textAlign="center" lineHeight="40px">
                                        <Text fontSize={tokens.fontSizes.large} fontWeight={tokens.fontWeights.bold}  textAlign="left">資本金</Text>
                                      </View>
                                      <View as="div" width={{base:"100%",large:"90%"}}>
                                        <Flex direction="row" gap={{base:"0.5rem",large:"1rem"}} lineHeight="40px" >
                                          <TextField isDisabled={!allowDetailConditionInput} inputStyles={{backgroundColor:`${tokens.colors.white}`,fontSize:`${tokens.fontSizes.large}`,width:"120px",height:"40px"}}  label="" placeholder="" ref={capitalFromRef}  fontSize={tokens.fontSizes.medium} gap="0" onBlur={() => confirmDetailConditionsIsEnterd(false)}/>
                                           <Flex justifyContent="space-between" gap="0" width={{base:"40px",large:"60px"}} fontSize={tokens.fontSizes.large}>
                                            <Text>万円</Text>
                                            <Text>～</Text>
                                          </Flex>
                                          <TextField isDisabled={!allowDetailConditionInput} inputStyles={{backgroundColor:`${tokens.colors.white}`,fontSize:`${tokens.fontSizes.large}`,width:"120px",height:"40px"}} label="" placeholder="" ref={capitalToRef}  fontSize={tokens.fontSizes.medium} gap="0" onBlur={() => confirmDetailConditionsIsEnterd(false)}/>
                                          <Text fontSize={tokens.fontSizes.large}>万円</Text>
                                        </Flex>
                                      </View>
                                    </Flex>
                                    <Flex direction={{base:"column",large:"row"}} gap={{base:"0",large:"15px"}} marginTop="10px">
                                      <View as="div" width="120px" textAlign="center" lineHeight="40px">
                                        <Text fontSize={tokens.fontSizes.large} fontWeight={tokens.fontWeights.bold}  textAlign="left">URL</Text>
                                      </View>
                                      <View as="div" width={{base:"100%",large:"90%"}} marginLeft={{base:"5px",large:"0"}}>
                                        <Flex direction="row" gap="1rem" lineHeight={{base:"25px",large:"40px"}}>
                                          <CheckboxField isDisabled={!allowDetailConditionInput} label="有" name="homepage" value="yes" gap="15px" size="large" marginRight="40px" inputStyles={{backgroundColor:`${tokens.colors.white}`}} checked={checkedHaveOrNotHaveHomePage[0]}
                                          onChange={() => {
                                              handleDetailRequirementsChecked(checkedHaveOrNotHaveHomePage,setCheckedHaveOrNotHaveHomePage,0);
                                              confirmDetailConditionsIsEnterd(true);
                                          }} 
                                           />
                                          <CheckboxField isDisabled={!allowDetailConditionInput} label="無" name="homepage" value="no" gap="15px" size="large" checked={checkedHaveOrNotHaveHomePage[1]} 
                                          onChange={() => {
                                            handleDetailRequirementsChecked(checkedHaveOrNotHaveHomePage,setCheckedHaveOrNotHaveHomePage,1);
                                            confirmDetailConditionsIsEnterd(true);
                                          }} 
                                           />
                                        </Flex>
                                      </View>
                                    </Flex>
                                    <Flex direction={{base:"column",large:"row"}} gap={{base:"0",large:"15px"}} marginTop={{base:"15px",large:"0px"}}>
                                      <View as="div" width="120px" textAlign="center" lineHeight="40px">
                                        <Text fontSize={tokens.fontSizes.large} fontWeight={tokens.fontWeights.bold}  textAlign="left">問合せURL</Text>
                                      </View>
                                      <View as="div" width={{base:"100%",large:"90%"}} marginLeft={{base:"5px",large:"0"}}>
                                        <Flex direction="row" gap="1rem" lineHeight={{base:"25px",large:"40px"}}>
                                          <CheckboxField isDisabled={!allowDetailConditionInput} label="有" name="inquiryPage" value="yes" gap="15px" size="large" marginRight="40px" checked={checkedHaveOrNotHaveContactPage[0]} 
                                            onChange={() => {
                                                handleDetailRequirementsChecked(checkedHaveOrNotHaveContactPage,setCheckedHaveOrNotHaveContactPage,0);
                                                confirmDetailConditionsIsEnterd(true); 
                                            }} 
                                            />
                                          <CheckboxField isDisabled={!allowDetailConditionInput} label="無" name="inquiryPage" value="no" gap="15px" size="large" checked={checkedHaveOrNotHaveContactPage[1]} 
                                            onChange={() => {
                                              handleDetailRequirementsChecked(checkedHaveOrNotHaveContactPage,setCheckedHaveOrNotHaveContactPage,1);
                                              confirmDetailConditionsIsEnterd(true);
                                            }} 
                                            />
                                        </Flex>
                                      </View>
                                    </Flex>
                                    <Flex direction={{base:"column",large:"row"}} gap={{base:"0",large:"15px"}} marginTop={{base:"15px",large:"0px"}}>
                                      <View as="div" width="120px" textAlign="center" lineHeight="40px">
                                        <Text fontSize={tokens.fontSizes.large} fontWeight={tokens.fontWeights.bold}  textAlign="left">上場・非上場</Text>
                                      </View>
                                      <View as="div" width={{base:"100%",large:"90%"}} marginLeft={{base:"5px",large:"0"}}>
                                        <Flex direction="row" gap="1rem" lineHeight={{base:"25px",large:"40px"}}>
                                          <CheckboxField isDisabled={!allowDetailConditionInput} label="上場" name="listed" value="yes" gap="15px" size="large" marginRight="27.5px" checked={checkedListedOrUnListedCompany[0]} 
                                            onChange={() => {
                                              handleDetailRequirementsChecked(checkedListedOrUnListedCompany,setCheckedListedOrUnListedCompany,0);
                                              confirmDetailConditionsIsEnterd(true);
                                            }}/>
                                          <CheckboxField isDisabled={!allowDetailConditionInput} label="非上場" name="listed" value="no" gap="15px" size="large" checked={checkedListedOrUnListedCompany[1]} 
                                            onChange={() => {
                                              handleDetailRequirementsChecked(checkedListedOrUnListedCompany,setCheckedListedOrUnListedCompany,1);
                                              confirmDetailConditionsIsEnterd(true);
                                            }}/>
                                        </Flex>
                                      </View>
                                    </Flex>
                                    <Flex direction={{base:"column",large:"row"}} gap={{base:"0",large:"15px"}} marginTop={{base:"15px",large:"0px"}}>
                                      <View as="div" width="120px" textAlign="center" lineHeight="40px">
                                        <Text fontSize={tokens.fontSizes.large} fontWeight={tokens.fontWeights.bold}  textAlign="left">閉鎖企業</Text>
                                      </View>
                                      <View as="div" width={{base:"100%",large:"90%"}} marginLeft={{base:"5px",large:"0"}}>
                                        <Flex direction="row" gap="1rem" lineHeight={{base:"25px",large:"40px"}}>
                                          <CheckboxField isDisabled={!allowDetailConditionInput} label="有" name="closed" value="yes" gap="15px" size="large" marginRight="40px" checked={checkedCloseOrOpenCompany[0]} 
                                            onChange={() => {
                                              handleDetailRequirementsChecked(checkedCloseOrOpenCompany,setCheckedCloseOrOpenCompany,0);
                                              confirmDetailConditionsIsEnterd(true);
                                            }} 
                                            />
                                          <CheckboxField isDisabled={!allowDetailConditionInput} label="無" name="closed" value="no" gap="15px" size="large" checked={checkedCloseOrOpenCompany[1]} 
                                            onChange={() => {
                                              handleDetailRequirementsChecked(checkedCloseOrOpenCompany,setCheckedCloseOrOpenCompany,1)
                                              confirmDetailConditionsIsEnterd(true);
                                            }}/>
                                        </Flex>
                                      </View>
                                    </Flex>
                                    <Flex direction={{base:"column",large:"row"}} gap={{base:"0",large:"15px"}} marginTop={{base:"15px",large:"0px"}}>
                                      <View as="div" width="120px" textAlign="center" lineHeight="40px">
                                        <Text fontSize={tokens.fontSizes.large} fontWeight={tokens.fontWeights.bold} textAlign="left">出力済み企業</Text>
                                      </View>
                                      <View as="div" width={{base:"100%",large:"90%"}} marginLeft={{base:"5px",large:"0"}}>
                                        <Flex direction="row" gap="1rem" lineHeight={{base:"25px",large:"40px"}}>
                                          <CheckboxField isDisabled={!allowDetailConditionInput} label="有" name="exported" value="yes" gap="15px" size="large" marginRight="40px" checked={checkedOutputOrNotOutputCompany[0]} 
                                            onChange={() => {
                                              handleDetailRequirementsChecked(checkedOutputOrNotOutputCompany,setCheckedOutputOrNotOutputCompany,0);
                                              confirmDetailConditionsIsEnterd(true);
                                            }}/>
                                          <CheckboxField isDisabled={!allowDetailConditionInput} label="無" name="exported" value="no" gap="15px" size="large" checked={checkedOutputOrNotOutputCompany[1]} 
                                            onChange={() => {
                                              handleDetailRequirementsChecked(checkedOutputOrNotOutputCompany,setCheckedOutputOrNotOutputCompany,1);
                                              confirmDetailConditionsIsEnterd(true);
                                            }}/>
                                        </Flex>
                                      </View>
                                    </Flex>
                                  </Flex>
                                </Accordion.Content>
                            </Accordion.Item>
                        </Accordion.Container>
                    </Flex>
                  </Flex>
                </Card>
              </Flex>
            
            {/* ボタンエリア */}
            <Flex direction={{base:"column",large:"row"}} justifyContent="center" gap="1.5rem" width="100%" marginTop={{base:"20px",large:"0"}} marginBottom="30px">
              <Button className="greenHoverButton" variation="primary" backgroundColor={tokens.colors.green[40]} color="white" margin={{base:"0 auto",large:"0"}} width={{base:"90%",large:"150px"}} height="40px" borderRadius="20px" onClick={() => handleSearchWithClick()}>
                <MdSearch style={{fontSize: `${tokens.fontSizes.xxxl}`, marginRight: "5px"}}/> 
                <View as="span" marginBottom="2px" fontSize="18px">検索する</View>
              </Button>
              <Button className="whiteHoverButton" variation="primary" backgroundColor={tokens.colors.white} borderColor={tokens.colors.blue[80]} color={tokens.colors.blue[80]} fontSize={tokens.fontSizes.large} width={{base:"90%",large:"150px"}} height="35px" borderRadius="18px" margin={{base:"0 auto",large:"2.5px 0 0 0"}} onClick={() => clearSearchRequirements()}>
                <View as="span" marginTop="2px">検索条件をリセット</View>
              </Button>
            </Flex>
            
            {/* エラーメッセージの表示 ()*/}
            {errorMessage2 && (
            <div ref={error2Ref} className="alertHome">
              <Alert
                isDismissible={false}
                variation="error"
                color="red"
                hasIcon={true}
                heading=""
                padding="1rem"
              >
                {errorMessage2}
              </Alert>
            </div>
            )}
            {tokenInfo && (tokenInfo.plan == 'free' || tokenInfo.plan == 'freePlus') && isNeedChangePlan && !isNeedChangePlan2 ? (
                <Button className="whiteHoverButton" onClick={handleNavigatePlanChange} marginTop="2rem" display="inline-flex" borderColor={tokens.colors.neutral[60]} backgroundColor={tokens.colors.white} color={tokens.colors.black} padding="1rem 3rem 0.5rem" borderRadius="0px">
                    <Flex gap="1rem" justifyContent="center">
                            <Text fontSize="1.5rem" fontWeight="500">プラン変更の案内</Text>
                            <View><MdNavigateNext fontSize="2rem" /></View>
                    </Flex>
                </Button>
            ):(<></>)}
            {/* 成功メッセージの表示 */}
            {successMessage && (
            <div ref={successRef} className="alertHome">
              <Message
                isDismissible={false}
                variation="outlined"
                colorTheme="success"
                hasIcon={true}
                heading=""
              >
                {successMessage}
              </Message>
            </div>
            )}
  
            {/* エラーメッセージの表示 */}
            {errorMessage3 && (
            <div ref={error3Ref} className="alertHome">
              <Alert
                isDismissible={false}
                variation="error"
                color="red"
                hasIcon={true}
                heading=""
                padding="1rem"
              >
                {errorMessage3}
              </Alert>
            </div>
            )}
            {tokenInfo && tokenInfo.plan == 'basic' && isNeedAddPurchase ? (
                <Button className="whiteHoverButton" onClick={handleNavigateAddPurchase} marginTop="2rem" display="inline-flex" borderColor={tokens.colors.neutral[60]} backgroundColor={tokens.colors.white} color={tokens.colors.black} padding="1rem 3rem 0.5rem" borderRadius="0px" >
                    <Flex gap="1rem" justifyContent="center">
                            <Text fontSize="1.5rem" fontWeight="500">出力数追加の案内</Text>
                            <View><MdNavigateNext fontSize="2rem" /></View>
                    </Flex>
                </Button>
            ):(<></>)}
            {tokenInfo && (tokenInfo.plan == 'free' || tokenInfo.plan == 'freePlus') && isNeedChangePlan2 ? (
                <Button className="whiteHoverButton" onClick={handleNavigatePlanChange} marginTop="2rem" display="inline-flex" borderColor={tokens.colors.neutral[60]} backgroundColor={tokens.colors.white} color={tokens.colors.black} padding="1rem 3rem 0.5rem" borderRadius="0px">
                    <Flex gap="1rem" justifyContent="center">
                            <Text fontSize="1.5rem" fontWeight="500">プラン変更の案内</Text>
                            <View><MdNavigateNext fontSize="2rem" /></View>
                    </Flex>
                </Button>
            ):(<></>)}
            {/* 検索結果セクション */}
            <Flex direction="column" width="100%" gap="0">
              {/* 検索結果セクション */}
            <Flex direction="row" gap="0">
              <Card width={{ base: "calc(100% - 190px)", large: "calc(100% - 350px)" }}  marginBottom="0" padding={{ base:"1rem 1.5rem", medium:"1rem 2rem" }} backgroundColor={tokens.colors.blue[60]} borderRadius="1rem 0 0 0">
                <Flex direction="row" alignItems="center" justifyContent="space-between" backgroundColor={tokens.colors.blue[60]}>
                  <Flex direction={{ base: "column-reverse", large: "row" }} alignItems="center" gap={{ base: "0.2rem", large: "1rem" }}>
                    <Image src={IcoResultTitle} alt="検索結果ロゴ"width={{ base: "50px", large: "80px" }} height={{ base: "50px", large: "80px" }}/>
                    <Flex direction={{ base: "column", large: "row" }} alignItems="center" gap={{ base: "0.2rem", large: "1rem" }}>
                      <Text fontWeight="bold" fontSize={{ base: tokens.fontSizes.xxl, large: tokens.fontSizes.xxxl }} color="white">検索結果</Text>
                      <Flex direction="row" alignItems="baseline">
                        <Text fontWeight="bold" fontSize={tokens.fontSizes.xxxl} color="white">{dataCount.toLocaleString()}</Text>
                        <Text fontWeight="bold" fontSize={tokens.fontSizes.xl} color="white" marginTop="0.5rem">件</Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Card>
              <Flex width={{ base: "190px", large: "350px" }} direction="column" gap="0">
                <Flex direction="row" alignItems="center" justifyContent={{ base: "center", large: "start" }} gap="1rem" backgroundColor={tokens.colors.blue[40]} borderRadius="0 1rem 0 0" height={{ base: "35%", large: "50%" }}>
                  <Text color="white" fontWeight="bold" fontSize="1.25rem" marginLeft="2%">表示件数</Text>
                  <SelectField label=""  width="10rem" fontWeight="bold" icon={<FaAngleDown size="1.5rem" />} marginBottom="0.5rem" className="numorder-pulldown"
                    value={rowsPerPage.toString()} onChange={handleRowsPerPageChange}  isDisabled={isSearching || isLoadingToken}>
                    {options}
                  </SelectField>
                </Flex>
                <Flex direction={{ base: "column", large: "row" }} alignItems="center" justifyContent={{ base: "space-evenly", large: "space-between" }} backgroundColor={tokens.colors.blue[80]} gap="2%" height={{ base: "65%", large: "50%" }}>
                  <Flex alignItems="center" gap="1rem" marginLeft="2%">
                    <Text color="white" fontWeight="bold" fontSize="1.25rem">並び替え</Text>
                    <SelectField label=""  width="10rem" fontWeight="bold" icon={<FaAngleDown size="1.5rem" />} marginBottom="0.5rem" className="kindorder-pulldown"
                      value={sortKey} onChange={handleSortChange} isDisabled={isSearching || isLoadingToken}>
                      <option value="corporateName">企業名</option>
                      <option value="headquarterAddress">住所</option>
                      <option value="industorialClass_large">業種</option>
                      <option value="captalStock">資本金</option>
                      <option value="numberOfEmployees">従業員数</option>
                    </SelectField>
                  </Flex>
                  <Flex alignItems="center" gap="2%" marginRight="2%" justifyContent="flex-end">
                    <Button
                      width="80px"
                      borderRadius="5rem"
                      backgroundColor={!isAscendingPush ? tokens.colors.blue[60] : 'white'}
                      color={!isAscendingPush ? 'white' : tokens.colors.blue[90]}
                      padding="0.5rem 5%"
                      maxWidth="45%"
                      onClick={() => handleOrderChange("DESC",false)}
                      isDisabled={isSearching || isLoadingToken}
                      className="whiteToBlueHoverButton"
                    >
                      <Flex display="inline-flex" alignItems="center" direction="row" gap="0.5rem">
                        <Text color="inherit" fontSize={tokens.fontSizes.xl}>降順</Text>
                        <View paddingTop="0.25rem" color="inherit"><ImArrowDown size="1.3rem" /></View>
                      </Flex>
                    </Button>
                    <Button
                      width="80px"
                      borderRadius="5rem"
                      backgroundColor={isAscendingPush ? tokens.colors.blue[60] : 'white'}
                      color={isAscendingPush ? 'white' : tokens.colors.blue[90]}
                      padding="0.5rem 5%"
                      maxWidth="45%"
                      onClick={() => handleOrderChange("ASC",true)}
                      isDisabled={isSearching || isLoadingToken}
                      className="whiteToBlueHoverButton"
                    >
                      <Flex display="inline-flex" alignItems="center" direction="row" gap="0.5rem">
                        <Text color="inherit" fontSize={tokens.fontSizes.xl}>昇順</Text>
                        <View paddingTop="0.25rem" color="inherit"><ImArrowUp size="1.3rem" /></View>
                      </Flex>
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
              {/* テーブル部分 */}
              {isSearchClicked && dataCount > 0 && (
                <View>
                  <Card width="100%" padding="0" style={{ overflowX: "auto" }}>
                    <Table width="100%" className="result-table" style={{tableLayout: "fixed", minWidth: minWidth }}>
                      <TableHead border="solid 1px black">
                        <TableRow>
                          <TableCell style={{border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue"}} width="3%" backgroundColor={tokens.colors.overlay[5]}>
                            <CheckboxField name="ALL" label="" size="large" alignItems="center" checked={masterChecked} onChange={handleMasterChange} />
                          </TableCell>
                          <TableCell style={{border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue"}} width="15%" backgroundColor={tokens.colors.overlay[5]} fontWeight="bold" fontSize={tokens.fontSizes.xl}>企業名</TableCell>
                          <TableCell style={{border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue"}} width="35%" backgroundColor={tokens.colors.overlay[5]} fontWeight="bold" fontSize={tokens.fontSizes.xl}>住所</TableCell>
                          <TableCell style={{border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue"}} width="15%" backgroundColor={tokens.colors.overlay[5]} fontWeight="bold" fontSize={tokens.fontSizes.xl}>業種</TableCell>
                          <TableCell style={{border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue"}} width="10%" backgroundColor={tokens.colors.overlay[5]} fontWeight="bold" fontSize={tokens.fontSizes.xl}>資本金</TableCell>
                          <TableCell style={{border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue"}} width="10%" backgroundColor={tokens.colors.overlay[5]} fontWeight="bold" fontSize={tokens.fontSizes.xl}>従業員</TableCell>
                          <TableCell style={{border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue"}} width="12%" backgroundColor={tokens.colors.overlay[5]} fontWeight="bold" fontSize={tokens.fontSizes.xl}>
                            <Flex padding="0 0.5rem" display="inline-flex" alignItems="center" gap="0">
                              <Text color="inherit">与信・ニュース</Text>
                                <Button color={tokens.colors.blue[60]} border="None" backgroundColor="inherit" padding="0.5rem" className="whiteHoverButton" onClick={handleOpenCreditNews}>
                                <GrCircleInformation fontSize="2.5rem"/>
                              </Button>
                            </Flex>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tableData.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell border="solid 1px lightgray" width="3%">
                              <CheckboxField name="row" label="" size="large" value={item.corporateNumber}  checked={otherChecked[indexOfFirstItem + index]} onChange={handleOtherChange(indexOfFirstItem + index)} />
                            </TableCell>
                            <TableCell border="solid 1px lightgray" width="15%" fontSize={tokens.fontSizes.large}>
                              <Link onClick={() => callCompanyDetail(item.corporateNumber)}
                                    onKeyPress={(e: React.KeyboardEvent<HTMLElement>) => {
                                      if (e.key === 'Enter' || e.key === ' ') {
                                        e.preventDefault();
                                        callCompanyDetail(item.corporateNumber);
                                      }
                                    }}
                                    role="button" tabIndex={0} className="LinkInHomeScreen">
                                {item.corporateName}
                              </Link>
                            </TableCell>
                            <TableCell border="solid 1px lightgray" width="35%" fontSize={tokens.fontSizes.large} style={{whiteSpace: hoveredIndex[index]?.addressHovered ? "pre-wrap" : "nowrap",
                              overflow: hoveredIndex[index]?.addressHovered ? "visible" : "hidden",
                              textOverflow: hoveredIndex[index]?.addressHovered ? "clip" : "ellipsis",
                              wordBreak: hoveredIndex[index]?.addressHovered ? "break-word" : "normal"}} 
                              onMouseEnter={() => handleAddressMouseEnter(index)} onMouseLeave={() => handleAddressMouseLeave(index)}>{item.headquarterAddress}
                            </TableCell>
                            <TableCell border="solid 1px lightgray" width="15%" fontSize={tokens.fontSizes.large} style={{whiteSpace: hoveredIndex[index]?.industryHovered ? "pre-wrap" : "nowrap",
                              overflow: hoveredIndex[index]?.industryHovered ? "visible" : "hidden",
                              textOverflow: hoveredIndex[index]?.industryHovered ? "clip" : "ellipsis",
                              wordBreak: hoveredIndex[index]?.industryHovered ? "break-word" : "normal"}}
                              onMouseEnter={() => handleIndustryMouseEnter(index)} onMouseLeave={() => handleIndustryMouseLeave(index)}>{item.industorialClass_large ? item.industorialClass_large : "-"}
                            </TableCell>
                            <TableCell className="right" border="solid 1px lightgray" width="8%" fontSize={tokens.fontSizes.large} style={{whiteSpace: hoveredIndex[index]?.capitalHovered ? "pre-wrap" : "nowrap",
                              overflow: hoveredIndex[index]?.capitalHovered ? "visible" : "hidden",
                              textOverflow: hoveredIndex[index]?.capitalHovered ? "clip" : "ellipsis",
                              wordBreak: hoveredIndex[index]?.capitalHovered ? "break-word" : "normal"}}
                              onMouseEnter={() => handleCapitalMouseEnter(index)} onMouseLeave={() => handleCapitalMouseLeave(index)}>{item.captalStock === 0 || item.captalStock === null ? "-" : item.captalStock.toLocaleString() + "円"}</TableCell>
                            <TableCell className="right" border="solid 1px lightgray" width="8%" fontSize={tokens.fontSizes.large} style={{whiteSpace: hoveredIndex[index]?.membersHovered ? "pre-wrap" : "nowrap",
                              overflow: hoveredIndex[index]?.membersHovered ? "visible" : "hidden",
                              textOverflow: hoveredIndex[index]?.membersHovered ? "clip" : "ellipsis",
                              wordBreak: hoveredIndex[index]?.membersHovered ? "break-word" : "normal"}}
                              onMouseEnter={() => handleMembersMouseEnter(index)} onMouseLeave={() => handleMembersMouseLeave(index)}>{item.numberOfEmployees === 0 || item.numberOfEmployees === null ? "-" : item.numberOfEmployees.toLocaleString() + "人"}</TableCell>
                            <TableCell border="solid 1px lightgray" width="14%" padding="0.5rem">
                              <Flex className="table-bottun" direction="row" justifyContent="space-between" padding="0 1rem">
                                <Button className="whiteHoverButton" borderColor={tokens.colors.neutral[60]} backgroundColor={tokens.colors.white} color={tokens.colors.blue[60]} onClick={() => callKigyoOdan(item.corporateName)}
                                display="flex" direction="column" alignItems="center" padding="0.25rem 1rem" gap="0.25rem">
                                  <IcoKigyou/><span>企業横断</span>
                                </Button>
                                <Button className="whiteHoverButton" borderColor={tokens.colors.neutral[60]} backgroundColor={tokens.colors.white} color={tokens.colors.blue[60]} onClick={() => callShinbunOdan(item.corporateName)}
                                display="flex" direction="column" alignItems="center" padding="0.25rem 1rem" gap="0.25rem">
                                  <IcoSinbun/><span>新聞横断</span>
                                  </Button>
                              </Flex>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Card>
                  <Flex direction={{base:"column-reverse", large:"row"}} marginTop="1rem">
                    <Button isDisabled={isSearching || isLoadingToken} className="greenHoverButton" fontSize="1.75rem" variation="primary" borderRadius="5rem" onClick={() => openCsvDlModal()} margin={{base:"0 auto",large:"0"}} width={{base:"260px",medium:"90%",large:"420px"}}>
                      <Flex padding="0 0.5rem" alignItems="center" gap="2rem">
                        <View paddingTop="0.25rem" color="inherit" ><IcoCsvTableHover height={"2rem"} width={"2rem"}/></View>
                        <Text color="inherit"><span>チェックした企業を</span><span>CSVダウンロード</span></Text>
                      </Flex>
                    </Button>
                    <Flex direction={{base:"column", large:"row"}} alignItems="center" gap={{base:"1rem", large:"0.5rem"}} margin={{base:"2rem auto", large:"0 0 0 auto"}}>
                      <Text>
                        {isSearching? '件表示':`${indexOfFirstItem + 1}〜${Math.min(indexOfLastItem, indexOfFirstItem + tableData.length)}件表示`}
                      </Text>
                      {/* Paginationコンポーネントの使用 */}
                      <HomeScreenPagination
                              currentPage={currentPage}
                              totalPages={totalPages}
                              onNext={handleNextPage}
                              onPrevious={handlePreviousPage}
                              onChange={handleChangePage}
                      />
                    </Flex>
                  </Flex>
                </View>
              )}
            </Flex>
          </Flex>

          <SaveSearchFilter modalIsOpen={saveSearchModalIsOpen} onRequestClose={closeSaveSearchModal} modalData={saveSearchData} onSave={handleSaveSearchData} />
          <CallSearchFilter modalIsOpen={callSearchModalIsOpen} onRequestClose={closeCallSearchModal} modalData={callSearchData} onCall={closeCallSearchModal} />
          <CsvDLConfirm isOpen={csvDLModalIsOpen} seachFilterRecord={searchFilterRecord} corporateNumberList={selectedCorporateNumberList} onDownload={DownloadCsv} onClose={closeCsvDlModal} />
        </View>
      </Flex>
      <Footer />
      </View>
    </>  

  );
}
